import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ImportState = {
  files: File[];
  project: string
};

const initialState: ImportState = {
  files: [],
  project: ''
};

const importSlice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    setImportProject(state, action: PayloadAction<string>) {
      state.project = action.payload;
    },
    setImportFiles(state, action: PayloadAction<File[]>) {
      state.files = action.payload;
    },
    addImportFile(state, action: PayloadAction<File>) {
      state.files = [...state.files, action.payload];
    },
    removeAllImportFiles(state) {
      state.files = [];
    },
    removeImportFile(state, action: PayloadAction<File>) {
      const newFiles: File[] = [];
      for (const file of state.files) {
        if (action.payload !== file) newFiles.push(file);
      }

      state.files = newFiles;
    },
  },
  selectors: {
    getImportProject: (state) => state.project,
    getImportFiles: (state) => state.files,
  }
});

export const {
  setImportFiles,
  addImportFile,
  removeAllImportFiles,
  removeImportFile,
  setImportProject
} = importSlice.actions;
export const { getImportFiles, getImportProject } = importSlice.selectors;
export default importSlice.reducer;
