import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'index.scss';

const rootDOM = document.getElementById('root');
if (rootDOM) {
  const root = ReactDOM.createRoot(rootDOM);
  root.render(<App />);
}
