import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./sections/AppRouter/AppRouter";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from "@mui/material";
import { theme } from "./lib/theme";
import { PersistGate } from "redux-persist/integration/react";

const PersistorContext = React.createContext(null) as any;

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <PersistorContext.Provider value={persistor}>
            <BrowserRouter>
              <AppRouter />
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                pauseOnHover
                draggable
                theme="light"
              />
              <img src='/assets/logo.svg' style={{position: 'fixed', bottom: 12, right: 12, left: 'unset'}} />
            </BrowserRouter>
          </PersistorContext.Provider>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
