import { ImageT } from "../../types/global";
import { calculateDistance } from "../measurements";

export const calculateDistance_rel = ({ startX, startY, endX, endY, zoom = 1 }: {
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  zoom?: number
}) => {
  return calculateDistance({startX: startX / zoom, startY: startY / zoom, endX: endX / zoom, endY: endY / zoom});
};

export const cleanCanvas = ({ ctx, width, height }: {
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number
}) => {
  ctx.beginPath();
  ctx.fillStyle = 'grey';
  ctx.fillRect(0, 0, width, height);
};

export const cleanCanvas_rel = ({ ctx, width, height, zoom = 1 }: {
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number,
  zoom?: number
}) => {
  cleanCanvas({ ctx, width: width / zoom, height: height / zoom });
};

export const isMouseInImg = ({ mouseX, mouseY, image, zoom = 1 }: {
  mouseX: number,
  mouseY: number,
  image: ImageT,
  zoom?: number
}) => {
  const shapeLeft = image.x * zoom;
  const shapeRight = (image.x + image.width) * zoom;
  const shapeTop = image.y * zoom;
  const shapeBottom = (image.y + image.height) * zoom;

  if (mouseX > shapeLeft && mouseX < shapeRight && mouseY > shapeTop && mouseY < shapeBottom) {
    return true;
  }

  return false;
};

export const drawImage = ({ctx, zoom = 1, image}: {
  ctx: CanvasRenderingContext2D,
  zoom?: number,
  image: ImageT
}) => {
  ctx.scale(zoom, zoom);

  if (image.source) {
    ctx.drawImage(image.source, image.x, image.y, image.width, image.height);
  }
};

export const redrawImage = ({ctx, canvasWidth, canvasHeight, zoom, image}: {
  ctx: CanvasRenderingContext2D,
  canvasWidth: number,
  canvasHeight: number,
  zoom?: number,
  image: ImageT
}) => {
  cleanCanvas_rel({ctx, width: canvasWidth, height: canvasHeight, zoom});
  drawImage({ctx, zoom, image});
};

// Calculates and returns width & height for fitting the canvas (with padding)
export const calculateFitSize = ({ padding, image, canvasWidth, canvasHeight }: {
  padding: number,
  image: HTMLImageElement,
  canvasWidth: number,
  canvasHeight: number,
}) => {
  let imgWidth: number;
  let imgHeight: number;
  const canvasWidthWithPadding = canvasWidth - padding * 2;
  const canvasHeightWithPadding = canvasHeight - padding * 2;
  if (image?.naturalWidth > image?.naturalHeight) {
    imgWidth = canvasWidthWithPadding;
    imgHeight = canvasWidthWithPadding * image?.naturalHeight / image?.naturalWidth;
  } else {
    imgHeight = canvasHeightWithPadding;
    imgWidth = canvasHeightWithPadding * image?.naturalWidth / image?.naturalHeight;
  }

  return { imgWidth, imgHeight };
};

// Calculates and returns offsets depending on canvas width & height
export const calculateOffsets = (canvas: HTMLCanvasElement) => {
  const canvasOffsets = canvas.getBoundingClientRect();

  return { offsetX: canvasOffsets.left, offsetY: canvasOffsets.top };
};
