import { createTheme, PaletteOptions } from "@mui/material";

export const theme = createTheme({
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1080,
      lg: 1360,
      xl: 1536
    }
  },
  typography: {
    fontFamily: 'Rubik',
    fontSize: 12,
    subtitle1: {
      fontSize: 13,
      fontWeight: 500
    }
  },
  palette: {
    primary: {
      main: '#0038FF',
      dark: '#0d3acc',
      light: '#6287ff',
      contrastText: 'white'
    }
  } as PaletteOptions,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
          borderRadius: '4px',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '110%',
          boxShadow: 'unset',
          textTransform: 'unset',

          '&:hover': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          backgroundColor: 'white',
          padding: '8px 10px !important',
        },
        input: {
          padding: '0 !important',
          fontSize: '12px',
          lineHeight: '110%',
        },
        sizeSmall: {
          padding: '5px 10px !important',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px',

          '&.Mui-disabled': {
            color: 'black',

            'input': {
              '-webkit-text-fill-color': 'black',
            },

            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #626262 !important',
            }
          },
        },

        notchedOutline: {
          fontSize: '12px',
          lineHeight: '110%',
          border: '1px solid #626262',
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          lineHeight: '110%',
          top: '-2px'
        },
        shrink: {
          top: '4px'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
          fontWeight: 400,
          color: '#C1C1C1',

          '&.Mui-selected': {
            color: 'black'
          }
        },
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          // borderBottom: '2px solid #C4C4C4'
        },
        indicator: {
          display: 'none'
        }
      }
    }
  }
});
