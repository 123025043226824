import React, { ReactNode } from 'react';
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "../Layout/AuthLayout";
import NonAuthLayout from "../Layout/NonAuthLayout";
import AuthorizedRoute from "./AuthorizedRoute";
import Main from "../../pages/Main";
import Auth from "../../pages/Auth";
import { isUserAuth } from "../../store/userSlice";

export type RouteConfig = {
  key: string;
  path: string;
  element: ReactNode;
};

export const ROUTES = Object.freeze({
  AUTH: {
    key: 'auth',
    path: '/auth'
  },
  MAIN: {
    key: 'main',
    path: '/'
  },
});

const authorizedRoutes: RouteConfig[] = [
  { ...ROUTES.MAIN, element: <Main /> },
];

const unauthorizedRoutes: RouteConfig[] = [
  { ...ROUTES.AUTH, element: <Auth /> },
];

const AppRouter: React.FC = () => {
  const isAuth = useSelector(isUserAuth);

  return (
    <Routes>

      {/* Public routes */}
      <Route element={<NonAuthLayout />}>
        <Route
          element={
            <AuthorizedRoute
              isAuth={!isAuth}
              redirectPath={ROUTES.MAIN.path}
            />
          }>
          {unauthorizedRoutes.map((route) => (
            <Route key={route.key} path={route.path} element={route.element} />
          ))}
        </Route>
      </Route>

      {/* Private routes */}
      <Route element={<AuthLayout />}>
        <Route element={
          <AuthorizedRoute isAuth={isAuth} />
        }>
          {authorizedRoutes.map((route) => (
            <Route key={route.key} path={route.path} element={route.element} />
          ))}
        </Route>
      </Route>

    </Routes>
  );
};

export default AppRouter;
