import React, { useMemo } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import ActionButton from "./ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { FileT, MeasureMode } from "../types/global";
import { getActiveFile, getMeasureMode, setMeasureMode } from "../store/globalSlice";
import { getBleaches, getColors, getPickSizes, setPickSize } from "../store/measurementSlice";

type BleachRowProps = {
  idx: 1 | 2 | 3;
}

const BleachRow: React.FC<BleachRowProps> = ({ idx }) => {
  const dispatch = useDispatch<AppDispatch>();

  const activeFile = useSelector(getActiveFile) as FileT;
  const measureMode = useSelector(getMeasureMode);
  const colors = useSelector(getColors);
  const bleaches = useSelector(getBleaches);
  const pickSizes = useSelector(getPickSizes);

  const pickType = useMemo(() => {
    switch (idx) {
      case 1:
        return MeasureMode.ColorPick1;
      case 2:
        return MeasureMode.ColorPick2;
      case 3:
        return MeasureMode.ColorPick3;
    }
  }, [idx]);

  const chooseRow = (idx: 1 | 2 | 3) => {
    let type: MeasureMode;
    switch (idx) {
      case 1:
        if (measureMode !== MeasureMode.ColorPick1) type = MeasureMode.ColorPick1;
        else type = MeasureMode.Unset;
        break;
      case 2:
        if (measureMode !== MeasureMode.ColorPick2) type = MeasureMode.ColorPick2;
        else type = MeasureMode.Unset;
        break;
      case 3:
        if (measureMode !== MeasureMode.ColorPick3) type = MeasureMode.ColorPick3;
        else type = MeasureMode.Unset;
        break;
    }
    dispatch(setMeasureMode(type));
  };

  const handleChangePickSize = (idx: 1 | 2 | 3, size: number) => {
    dispatch(setPickSize({ idx, value: size }));
  };

  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={2}
      sx={{
        backgroundColor: measureMode === pickType ? '#0000FF1A' : 'unset',
        padding: '6px 10px',
      }}
    >
      <Typography sx={{ width: 100 }}>Bleach score {idx}:</Typography>
      <TextField
        size='small'
        sx={{
          width: 70,
          borderRadius: '4px',
        }}
        type='number'
        inputProps={{
          min: 0
        }}
        value={pickSizes[idx]}
        disabled={Boolean(activeFile?.transformedFile) || !activeFile}
        onChange={(e) => handleChangePickSize(idx, Number(e.target.value))}
      />
      <ActionButton
        onClick={() => chooseRow(idx)}
        image='assets/colorpick.svg'
        disabled={Boolean(activeFile?.transformedFile) || !activeFile}
        active={measureMode === pickType}
      />
      <TextField
        size='small'
        sx={{
          width: 70,
          borderColor: 'transparent',
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: '4px'
        }}
        value={bleaches[idx] ?? ''}
        disabled
      />
      {(colors && colors[idx]) &&
        <Box sx={{
          borderRadius: '6px',
          width: 20,
          height: 20,
          backgroundColor: `rgb(${colors?.[idx]?.r}, ${colors?.[idx]?.g}, ${colors?.[idx]?.b})`
        }} />
      }
    </Stack>
  );
};

export default BleachRow;
