import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { getCurrentProject, setCurrentProject } from "../store/globalSlice";
import { getImportProject, setImportProject } from "../store/importSlice";

const BreadCrumbs = () => {
  const dispatch = useDispatch<AppDispatch>();

  const currentProject = useSelector(getCurrentProject);
  const project = useSelector(getImportProject);

  const onResetCurrentProject = () => {
    dispatch(setCurrentProject(undefined));
    dispatch(setImportProject(''));
  };

  return (
    <Stack direction='row' alignItems='center' gap={2}>
      {currentProject !== undefined ?
        <Typography sx={{ cursor: 'pointer', transition: '0.2s', color: '#8c8c8c', '&:hover': { color: '#0038FF' } }}
                    onClick={onResetCurrentProject} variant='subtitle1'>
          Projects
        </Typography> :
        <Typography variant='subtitle1'>
          Projects
        </Typography>
      }
      {currentProject &&
        <Stack direction='row' alignItems='center' gap={2}>
          <Typography variant='subtitle1'>/</Typography>
          <Typography variant='subtitle1'>{currentProject}</Typography>
        </Stack>
      }
      {currentProject === '' &&
        <Stack direction='row' alignItems='center' gap={2}>
          <Typography variant='subtitle1'>/</Typography>
          <TextField
            size='small'
            sx={{
              width: 70,
              borderColor: 'transparent',
              borderWidth: 1,
              borderStyle: 'solid',
              borderRadius: '4px'
            }}
            value={project}
            onChange={(e) => dispatch(setImportProject(e.target.value))}
          />
        </Stack>
      }
    </Stack>
  );
};

export default BreadCrumbs;
