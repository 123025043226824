import {
    Box,
    FormControl,
    FormControlLabel,
    InputAdornment,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store';
import React, { ChangeEvent, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    getCurrentFilter,
    getSorting,
    setCurrentFilter,
    setSorting,
} from '../store/globalSlice';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const Filter = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const sort = useSelector(getSorting);

    const setSort = (value: 'asc' | 'desc' | 'none') => {
        dispatch(setSorting(value));
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const dispatch = useDispatch<AppDispatch>();

    const filter = useSelector(getCurrentFilter);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setCurrentFilter(e.target.value));
    };

    const onResetFilter = () => {
        dispatch(setCurrentFilter(''));
    };

    return (
        <Stack
            direction="row"
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={3}>
            <FormControl sx={{ width: '70%' }}>
                <InputLabel>Search</InputLabel>
                <OutlinedInput
                    fullWidth
                    value={filter}
                    size="small"
                    onChange={onChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon sx={{ width: 14 }} />
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <CancelIcon
                                onClick={onResetFilter}
                                sx={{
                                    cursor: 'pointer',
                                    width: 16,
                                    transition: '0.2s',
                                    '&:hover': { color: '#c0c0c0' },
                                }}
                            />
                        </InputAdornment>
                    }
                    label="Search"
                />
            </FormControl>
            <Box
                onClick={handleClick}
                sx={{
                    borderRadius: '6px',
                    cursor: 'pointer',
                    // border: '2px solid #0000FF',
                    width: '24px',
                    height: '24px',
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <SwapVertIcon />
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    value={sort}
                    onChange={(e) =>
                        setSort(e.target.value as 'asc' | 'desc' | 'none')
                    }
                    name="radio-buttons-group">
                    <MenuItem onClick={handleClose}>
                        <FormControlLabel
                            value="none"
                            control={<Radio />}
                            label="None"
                        />
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <FormControlLabel
                            value="asc"
                            control={<Radio />}
                            label="Ascending"
                        />
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        {' '}
                        <FormControlLabel
                            value="desc"
                            control={<Radio />}
                            label="Descending"
                        />
                    </MenuItem>
                </RadioGroup>
            </Menu>
        </Stack>
    );
};

export default Filter;
