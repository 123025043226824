import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Preset } from "types/global";

type PresetState = {
  presets: Preset[];
  current: Preset | null;
};

const initialState: PresetState = {
  presets: [],
  current: null
};

const presetSlice = createSlice({
  name: 'preset',
  initialState,
  reducers: {
    setPresets: (state, action: PayloadAction<Preset[]>) => {
      state.presets = action.payload;
    },
    addPreset: (state, action: PayloadAction<Preset>) => {
      state.presets = [...state.presets, action.payload];
    },
    setCurrentPreset: (state, action: PayloadAction<Preset | null>) => {
      state.current = action.payload;
    }
  },
  selectors: {
    getPresets: (state) => state.presets,
    getCurrentPreset: (state) => state.current
  }
});

export const { setPresets, addPreset, setCurrentPreset } = presetSlice.actions;
export const { getPresets, getCurrentPreset } = presetSlice.selectors;
export default presetSlice.reducer;
