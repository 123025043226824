export enum MeasureMode {
  Unset = 'unset',
  ColorPick1 = 'colorpick1',
  ColorPick2 = 'colorpick2',
  ColorPick3 = 'colorpick3',
  Mask = 'mask',
  MaskEraser = 'maskEraser',
  Measure1 = 'measurement1',
  Measure2 = 'measurement2',
  Measure3 = 'measurement3',
  Measure4 = 'measurement4'
}

export type ColorT = {
  r: number;
  g: number;
  b: number;
  a?: number;
};

export type PointT = { x: number; y: number; size?: number };

export type LineT = { start: PointT; end: PointT };

export type CoordT = string; // like 'x,y'

export type ImageT = {
  x: number;
  y: number;
  width: number;
  height: number;
  source?: CanvasImageSource;
};

export type FileT = {
  file?: string;
  transformedFile?: string;
  id: string;
  name: string;
  date?: string;
  location?: string;
  experiment?: string;
  frame?: string;
  comment1?: string;
  comment2?: string;
  project?: string;
  status?: 'new' | 'inwork';
  bleaches?: {
    1?: string;
    number1?: string;
    2?: string;
    3?: string;
    total?: string;
  };
  measurements?: {
    1?: string;
    2?: string;
    3?: string;
    4?: string;
  };
  colors?: {
    1: string;
    2: string;
    3: string;
  };
};
export type Bound = { min: number; max: number };

export enum Tab {
  Import = 'import',
  Export = 'export',
  Main = 'main'
}

export type Preset = {
  _id: string;
  brightness: number[];
}
