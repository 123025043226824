import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import Dropzone from 'react-dropzone';
import Projects from '../../ui/Projects';
import {
  addImportFile,
  getImportFiles,
  removeImportFile
} from '../../store/importSlice';
import {
  getCurrentFilter,
  getCurrentProject,
  getLoading
} from '../../store/globalSlice';
import BreadCrumbs from '../../ui/BreadCrumbs';
import Filter from '../../ui/Filter';
import CancelIcon from '@mui/icons-material/Cancel';

const baseStyle = {
  flex: 1,
  margin: '10px 16px',
  display: 'flex',
  justifyContent: 'center',
  padding: '10px',
  borderWidth: 1,
  borderColor: '#CACACA',
  borderStyle: 'dashed',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
};

const ImportLeft: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();

  const files = useSelector(getImportFiles);
  const loading = useSelector(getLoading);
  const filter = useSelector(getCurrentFilter);
  const currentProjectName = useSelector(getCurrentProject);

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      onAddFile(file);
    });
  };

  const onAddFile = (file?: File) => {
    if (!file) return;

    dispatch(addImportFile(file));
  };

  const onFileRemove = (file: File) => {
    dispatch(removeImportFile(file));
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Stack m={4} gap={4}>
        <BreadCrumbs />

        <Filter />
      </Stack>

      <Divider />
      {currentProjectName === undefined ? (
        <Projects canAddNew />
      ) : (
        <Box>
          <Dropzone
            accept={{
              'image/png': ['.png'],
              'image/jpg': ['.jpg', '.jpeg']
            }}
            onDrop={onDrop}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ style: baseStyle })}>
                  <input {...getInputProps()} />
                  <Typography>
                    Drag file or{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                      add
                    </span>
                  </Typography>
                </div>
              </section>
            )}
          </Dropzone>

          <Box
            sx={{ overflowY: 'scroll', height: 'calc(100vh - 216px)' }}
          >
            <Stack>
              {loading ? (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                files?.length !== 0 &&
                files?.map((file, idx) => {
                  if ((filter && file.name.includes(filter)) || !filter)
                    return (
                      <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        px={4}
                        py={2}
                      >
                        <Stack
                          direction='row'
                          key={idx}
                          gap={2}
                          alignItems='flex-end'
                        >
                          <img
                            src={URL.createObjectURL(file)}
                            alt=''
                            width={45}
                          />
                          <Typography
                            sx={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              color: '#626262',
                              width: '240px'
                            }}
                          >
                            {file.name}
                          </Typography>
                        </Stack>

                        <CancelIcon
                          onClick={() => onFileRemove(file)}
                          sx={{
                            cursor: 'pointer',
                            color: 'grey',
                            transition: '0.2s',
                            '&:hover': { color: 'blue' }
                          }}
                        />
                      </Stack>
                    );
                })
              )}
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ImportLeft;
