import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import Projects from '../../ui/Projects';
import {
  addExportId,
  getExportIds,
  removeExportId
} from '../../store/exportSlice';
import {
  getCurrentFilter,
  getCurrentProject,
  getSavedData,
  getSorting,
  setLoading,
  setProjectData
} from '../../store/globalSlice';
import BreadCrumbs from '../../ui/BreadCrumbs';
import Filter from '../../ui/Filter';
import { defaultApi } from '../../api/default';
import { FileT } from 'types/global';

const ExportLeft = () => {
  const dispatch = useDispatch<AppDispatch>();

  const currentProjectName = useSelector(getCurrentProject);
  const sorting = useSelector(getSorting);
  const saved = useSelector(getSavedData);
  const savedSorted = saved ? [...(saved as FileT[])] : [];
  const transformed =
    sorting === 'none'
      ? savedSorted
      : sorting === 'asc'
        ? [...savedSorted].sort((a, b) => a.name.localeCompare(b.name))
        : [...savedSorted].sort((a, b) => b.name.localeCompare(a.name));
  const ids = useSelector(getExportIds);
  const filter = useSelector(getCurrentFilter);

  const [selected, setSelected] = useState<{ id: string; idx: number }[]>([]);

  useEffect(() => {
    if (!saved) {
      onFetchCurrentProjectData();
    }
  }, [currentProjectName]);

  const onFetchCurrentProjectData = async () => {
    if (!currentProjectName) return;

    try {
      dispatch(setLoading(true));
      const data = await defaultApi.getAll(currentProjectName);
      dispatch(setProjectData({ project: currentProjectName, data }));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onClick = (
    e: React.MouseEvent<HTMLLabelElement>,
    id: string,
    idx: number
  ) => {
    const target = e.target as HTMLInputElement;

    let newSelected: { id: string; idx: number }[] = selected;
    const ids = Object.values(newSelected).map((i) => i.id);

    if (e.shiftKey && newSelected.length > 0) {
      console.log(newSelected);
      let endIdx = idx;
      let startIdx = newSelected[newSelected.length - 1].idx;
      const asc = startIdx < endIdx;
      startIdx = ids.includes(transformed[asc ? startIdx + 1 : startIdx - 1].id)
        ? startIdx
        : asc
          ? startIdx + 1
          : startIdx - 1;
      endIdx = !ids.includes(transformed[endIdx].id)
        ? endIdx
        : asc
          ? endIdx - 1
          : endIdx + 1;

      for (let i = startIdx; asc ? i <= endIdx : i >= endIdx; asc ? i++ : i--) {
        console.log(i);

        const id = transformed[i].id;
        if (!ids.includes(id)) {
          const idx = transformed.findIndex((f) => f.id === id);
          dispatch(addExportId(id));
          newSelected.push({ id, idx });
        } else {
          dispatch(removeExportId(id));
          newSelected = newSelected.filter((f) => f.id !== id);
        }
      }
    } else {
      if (target.checked) {
        newSelected.push({ id, idx });
        dispatch(addExportId(id));
      } else {
        newSelected = newSelected.filter((f) => f.id !== id);
        dispatch(removeExportId(id));
      }
    }

    setSelected(newSelected);
  };

  return (
    <Stack>
      <Stack m={4} gap={4}>
        <BreadCrumbs />
        <Filter />
      </Stack>

      <Divider />
      {!currentProjectName ? (
        <Projects />
      ) : (
        <Box
          style={{ overflowY: 'scroll', height: 'calc(100vh - 52px - 98px)' }}
        >
          <Stack>
            {saved &&
              saved?.length !== 0 &&
              transformed?.map((file, idx) => {
                if ((filter && file.name.includes(filter)) || !filter)
                  return (
                    <Stack
                      direction='row'
                      key={idx}
                      px={4}
                      py={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{
                        cursor: 'pointer',
                        transition: '0.2s',
                        '& .MuiFormControlLabel-root': {
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }
                      }}
                    >
                      <FormControlLabel
                        sx={{ margin: 0 }}
                        labelPlacement='start'
                        onClick={(e) => onClick(e, file.id, idx)}
                        control={
                          <Checkbox
                            checkedIcon={
                              <img
                                src='/assets/checkbox.svg'
                                style={{ width: '14px', height: '14px' }}
                              />
                            }
                            icon={
                              <img
                                src='/assets/blank_checkbox.svg'
                                style={{ width: '14px', height: '14px' }}
                              />
                            }
                            checked={ids.includes(file.id)}
                          />
                        }
                        label={
                          <Stack direction='row' gap={2} alignItems='flex-end'>
                            <Typography
                              sx={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: 280,
                                color: '#626262'
                              }}
                            >
                              {file.name}
                            </Typography>
                          </Stack>
                        }
                      />
                    </Stack>
                  );
              })}
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export default ExportLeft;
