import { LineT } from "../../types/global";
import { drawPoint, drawPoint_rel } from "./point";

export const drawLine = ({ ctx, line }: {
  ctx: CanvasRenderingContext2D,
  line: LineT,
}) => {
  if (!ctx) return;

  ctx.beginPath();
  ctx.moveTo(line.start.x, line.start.y);
  ctx.lineTo(line.end.x, line.end.y);
  ctx.strokeStyle = 'red';
  ctx.lineWidth = 2;
  ctx.stroke();
};

export const drawLine_rel = ({ ctx, line, zoom = 1 }: {
  ctx: CanvasRenderingContext2D,
  line: LineT,
  zoom?: number
}) => {
  const relStartX = line.start.x / zoom;
  const relStartY = line.start.y / zoom;
  const relEndX = line.end.x / zoom;
  const relEndY = line.end.y / zoom;
  drawLine({ ctx, line: { start: { x: relStartX, y: relStartY }, end: { x: relEndX, y: relEndY } } });
};

export const drawAllLines = ({ ctx, lines }: {
  ctx: CanvasRenderingContext2D,
  lines: LineT[]
}) => {
  for (const line of lines) {
    drawPoint({ ctx, x: line.start.x, y: line.start.y });
    drawLine({ ctx, line });
    drawPoint({ ctx, x: line.end.x, y: line.end.y });
  }
}

export const drawAllLines_rel = ({ ctx, lines, zoom = 1 }: {
  ctx: CanvasRenderingContext2D,
  lines: LineT[],
  zoom?: number
}) => {
  for (const line of lines) {
    drawLine_rel({ ctx, line, zoom });
  }
}

export const translateAllLines = ({ ctx, dx, dy, lines, setLines }: {
  ctx: CanvasRenderingContext2D,
  dx: number,
  dy: number,
  lines: LineT[],
  setLines: (lines: LineT[]) => void
}) => {
  const newLines: LineT[] = [];
  for (const line of lines) {
    const newStartX = line.start.x + dx;
    const newStartY = line.start.y + dy;
    const newEndX = line.end.x + dx;
    const newEndY = line.end.y + dy;
    const newLine = { start: { x: newStartX, y: newStartY }, end: { x: newEndX, y: newEndY } };
    drawLine({ ctx, line: newLine });
    drawPoint({ ctx, x: newStartX, y: newStartY });
    drawPoint({ ctx, x: newEndX, y: newEndY });
    newLines.push(newLine);
  }
  setLines(newLines);
};

export const translateAllLines_rel = ({ ctx, dx, dy, lines, zoom = 1, setLines }: {
  ctx: CanvasRenderingContext2D,
  dx: number,
  dy: number,
  lines: LineT[],
  zoom?: number,
  setLines: (lines: LineT[]) => void
}) => {
  const newLines: LineT[] = [];
  for (const line of lines) {
    const newStartX = line.start.x + dx;
    const newStartY = line.start.y + dy;
    const newEndX = line.end.x + dx;
    const newEndY = line.end.y + dy;
    const newLine = { start: { x: newStartX, y: newStartY }, end: { x: newEndX, y: newEndY } };
    drawLine_rel({ ctx, line: newLine, zoom });
    drawPoint_rel({ ctx, x: newStartX, y: newStartY, zoom });
    drawPoint_rel({ ctx, x: newEndX, y: newEndY, zoom });
    newLines.push(newLine);
  }
  setLines(newLines);
};
