import { API } from "./index";
import { ENDPOINTS } from "../const/endpoints";

export type MeasurementBodyT = {
  id: string,
  blch1?: string,
  color1?: string,
  blch1_number?: string,
  blch2?: string,
  color2?: string,
  blch3?: string,
  color3?: string,
  step_blch?: string,
  control_size_mm?: string,
  width_size_mm?: string,
  heigth_size_mm?: string,
  bold_size_mm?: string,
  status?: string,
  project?: string
}

export const defaultApi = {
  get(id: string) {
    return API.get(ENDPOINTS.DEFAULT.GET, { params: { id } });
  },
  delete(id: string) {
    return API.delete(ENDPOINTS.DEFAULT.DELETE, { params: { id } });
  },
  getTransformed(id: string) {
    return API.get(ENDPOINTS.DEFAULT.GET_TRANSFORMED, { params: { id } });
  },
  getAll(project: string) {
    return API.get(ENDPOINTS.DEFAULT.GET_ALL, { params: { project } });
  },
  update(body: MeasurementBodyT) {
    return API.post(ENDPOINTS.DEFAULT.UPDATE, body);
  },
  getProjects() {
    return API.get(ENDPOINTS.DEFAULT.PROJECTS);
  }
};
