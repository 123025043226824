import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ExportState = {
  ids: string[],
  checks: {
    metadata: boolean;
    bleachScores: boolean;
    measurements: boolean;
    comments: boolean;
  }
};

const initialState: ExportState = {
  ids: [],
  checks: {
    metadata: true,
    bleachScores: true,
    measurements: true,
    comments: true,
  }
};

const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    setExportCheck(state, action: PayloadAction<{
      name: 'metadata' | 'bleachScores' | 'measurements' | 'comments',
      value: boolean
    }>) {
      const { name, value } = action.payload;
      state.checks[name] = value;
    },
    addExportId(state, action: PayloadAction<string>) {
      state.ids = [...state.ids, action.payload];
    },
    removeExportId(state, action: PayloadAction<string>) {
      const newIds: string[] = [];
      for (const id of state.ids) {
        if (id !== action.payload) newIds.push(id);
      }

      state.ids = newIds;
    },
    resetExport(state) {
      state.ids = initialState.ids;
      state.checks = initialState.checks;
    }
  },
  selectors: {
    getExportChecks: (state) => state.checks,
    getExportIds: (state) => state.ids,
  }
});

export const { setExportCheck, addExportId, removeExportId, resetExport } = exportSlice.actions;
export const { getExportChecks, getExportIds } = exportSlice.selectors;
export default exportSlice.reducer;
