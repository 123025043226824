import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FileT, MeasureMode } from '../../types/global';
import { defaultApi, MeasurementBodyT } from '../../api/default';
import { toast } from 'react-toastify';
import * as moment from 'moment';
import BleachRow from '../../ui/BleachRow';
import MeasurementRow from '../../ui/MeasurementRow';
import TransformImage from '../../ui/TransformImage';
import {
  addProjectData,
  getActiveFile,
  getCurrentProject,
  getMeasureMode,
  getSavedData,
  getSorting,
  getTab,
  getUploadedData,
  removeProjectData,
  setActiveFile,
  setMeasureMode
} from '../../store/globalSlice';
import {
  getBleaches,
  getColors,
  getMaskColor,
  getMaskEraserSize,
  getMaskSize,
  getMeasurementMultiplier,
  getMeasurements,
  getPickSizes,
  getTotalBleach,
  setMaskEraserSize,
  setMaskSize
} from '../../store/measurementSlice';
import ActionButton from '../../ui/ActionButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Tab as TabT } from '../../types/global';
import {
  fetchPresets,
  fetchSavedThunk,
  fetchUploadedThunk
} from 'store/thunks';
import Presets from 'ui/Presets';
import { getPresets, setCurrentPreset } from 'store/presetSlice';
import SettingsIcon from '@mui/icons-material/Settings';
import Settings from 'ui/Settings';
import ManualLink from 'ui/ManualLink';

const WorkspaceRight: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const globalTab = useSelector(getTab);
  const currentProjectName = useSelector(getCurrentProject) as string;
  const activeFile = useSelector(getActiveFile) as FileT;
  const colors = useSelector(getColors);
  const maskColor = useSelector(getMaskColor);
  const bleaches = useSelector(getBleaches);
  const pickSizes = useSelector(getPickSizes);
  const maskSize = useSelector(getMaskSize);
  const maskEraserSize = useSelector(getMaskEraserSize);
  const totalBleach = useSelector(getTotalBleach);
  const measurements = useSelector(getMeasurements);
  const measurementMultiplier = useSelector(getMeasurementMultiplier);
  const measureMode = useSelector(getMeasureMode);
  const sorting = useSelector(getSorting);
  const uploaded = useSelector(getUploadedData);
  const uploadedSorted = uploaded ? [...(uploaded as FileT[])] : [];
  const transformedUploaded =
    sorting === 'none'
      ? uploadedSorted
      : sorting === 'asc'
        ? [...uploadedSorted].sort((a, b) => a.name.localeCompare(b.name))
        : [...uploadedSorted].sort((a, b) => b.name.localeCompare(a.name));
  const saved = useSelector(getSavedData);
  const savedSorted = saved ? [...(saved as FileT[])] : [];
  const transformedSaved =
    sorting === 'none'
      ? savedSorted
      : sorting === 'asc'
        ? [...savedSorted].sort((a, b) => a.name.localeCompare(b.name))
        : [...savedSorted].sort((a, b) => b.name.localeCompare(a.name));
  const presets = useSelector(getPresets);

  const [measureTab, setMeasureTab] = useState<'dot' | 'mask'>('dot');
  const [tab, setTab] = useState<
    'metadata' | 'presets' | 'bleach' | 'shortcuts'
  >('metadata');
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [measurementsOpen, setMeasurementsOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchPresets());
  }, []);

  useEffect(() => {
    document.onkeydown = (e) => {
      if (globalTab === TabT.Main) {
        // e.preventDefault();
        // e.stopPropagation();

        if (e.key === 's' || e.key === 'd' || e.key === 'ы' || e.key === 'в') {
          if (activeFile) {
            if (
              activeFile.status === 'new' &&
              uploaded &&
              uploaded.length > 1
            ) {
              const activeFileIdx = transformedUploaded.findIndex(
                (file) => file.id === activeFile.id
              );
              if (
                activeFileIdx !== -1 &&
                activeFileIdx !== transformedUploaded.length - 1
              ) {
                dispatch(
                  fetchUploadedThunk(transformedUploaded[activeFileIdx + 1])
                );
              } else if (activeFileIdx === transformedUploaded.length - 1) {
                dispatch(fetchUploadedThunk(transformedUploaded[0]));
              }
            }
            if (activeFile.status === 'inwork' && saved && saved.length > 1) {
              const activeFileIdx = transformedSaved.findIndex(
                (file) => file.id === activeFile.id
              );
              if (
                activeFileIdx !== -1 &&
                activeFileIdx !== transformedSaved.length - 1
              ) {
                dispatch(fetchSavedThunk(transformedSaved[activeFileIdx + 1]));
              } else if (activeFileIdx === transformedSaved.length - 1) {
                dispatch(fetchSavedThunk(transformedSaved[0]));
              }
            }
          }
        }
        if (e.key === 'a' || e.key === 'w' || e.key === 'ф' || e.key === 'ц') {
          if (activeFile) {
            if (
              activeFile.status === 'new' &&
              uploaded &&
              uploaded.length > 1
            ) {
              const activeFileIdx = transformedUploaded.findIndex(
                (file) => file.id === activeFile.id
              );
              if (activeFileIdx !== -1 && activeFileIdx !== 0) {
                dispatch(
                  fetchUploadedThunk(transformedUploaded[activeFileIdx - 1])
                );
              } else if (activeFileIdx === 0) {
                dispatch(
                  fetchUploadedThunk(
                    transformedUploaded[transformedUploaded.length - 1]
                  )
                );
              }
            }
            if (activeFile.status === 'inwork' && saved && saved.length > 1) {
              const activeFileIdx = transformedSaved.findIndex(
                (file) => file.id === activeFile.id
              );
              if (activeFileIdx !== -1 && activeFileIdx !== 0) {
                dispatch(fetchSavedThunk(transformedSaved[activeFileIdx - 1]));
              } else if (activeFileIdx === 0) {
                dispatch(
                  fetchSavedThunk(transformedSaved[transformedSaved.length - 1])
                );
              }
            }
          }
        }

        if (e.shiftKey) {
          // Shift +

          if ((e.key === 'Z' || e.key === 'z') && presets.length > 0) {
            setTab('presets');
            dispatch(setCurrentPreset(presets[0]));
          }
          if ((e.key === 'X' || e.key === 'x') && presets.length > 1) {
            setTab('presets');
            dispatch(setCurrentPreset(presets[1]));
          }
          if ((e.key === 'C' || e.key === 'c') && presets.length > 2) {
            setTab('presets');
            dispatch(setCurrentPreset(presets[2]));
          }

          if (activeFile) {
            switch (e.key) {
              // 1
              case '!': {
                if (measureMode !== MeasureMode.ColorPick1)
                  dispatch(setMeasureMode(MeasureMode.ColorPick1));
                else dispatch(setMeasureMode(MeasureMode.Unset));
                break;
              }
              // 2
              case '@':
              case '"': {
                if (measureMode !== MeasureMode.ColorPick2)
                  dispatch(setMeasureMode(MeasureMode.ColorPick2));
                else dispatch(setMeasureMode(MeasureMode.Unset));
                break;
              }
              // 3
              case '#':
              case '№': {
                if (measureMode !== MeasureMode.ColorPick3)
                  dispatch(setMeasureMode(MeasureMode.ColorPick3));
                else dispatch(setMeasureMode(MeasureMode.Unset));
                break;
              }
              // Tab
              case 'Tab': {
                if (measureTab === 'dot') setMeasureTab('mask');
                else setMeasureTab('dot');
                break;
              }
            }
            // Shift + Enter
            if (e.key === 'Enter') {
              onSubmit();
            }
          }
        } else if (e.ctrlKey) {
          e.preventDefault();
          e.stopPropagation();

          // Ctrl +
          switch (e.key) {
            // 1
            case '1': {
              if (measureMode !== MeasureMode.Measure1)
                dispatch(setMeasureMode(MeasureMode.Measure1));
              else dispatch(setMeasureMode(MeasureMode.Unset));
              break;
            }
            // 2
            case '2': {
              if (measureMode !== MeasureMode.Measure2)
                dispatch(setMeasureMode(MeasureMode.Measure2));
              else dispatch(setMeasureMode(MeasureMode.Unset));
              break;
            }
            // 3
            case '3': {
              if (measureMode !== MeasureMode.Measure3)
                dispatch(setMeasureMode(MeasureMode.Measure3));
              else dispatch(setMeasureMode(MeasureMode.Unset));
              break;
            }
            // 4
            case '4': {
              if (measureMode !== MeasureMode.Measure4)
                dispatch(setMeasureMode(MeasureMode.Measure4));
              else dispatch(setMeasureMode(MeasureMode.Unset));
              break;
            }
          }
        }
      }
    };
  }, [measureTab, globalTab, measureMode, activeFile, presets]);

  const construct: () => {
    requestBody: MeasurementBodyT;
    newFile: Partial<FileT>;
  } = () => {
    let color1 = '';
    let color2 = '';
    let color3 = '';

    if (measureTab !== 'mask') {
      if (colors?.[1])
        color1 = `${colors?.[1]?.r},${colors?.[1]?.g},${colors?.[1]?.b}`;

      if (colors?.[2])
        color2 = `${colors?.[2]?.r},${colors?.[2]?.g},${colors?.[2]?.b}`;

      if (colors?.[3])
        color3 = `${colors?.[3]?.r},${colors?.[3]?.g},${colors?.[3]?.b}`;
    } else if (measureTab === 'mask' && maskColor) {
      color1 = `${maskColor?.r},${maskColor?.g},${maskColor?.b}`;
    }

    return {
      requestBody: {
        id: activeFile.id,
        blch1: bleaches[1] && measureTab !== 'mask' ? String(bleaches[1]) : '',
        color1: color1,
        blch1_number: String(pickSizes[1]),
        blch2: bleaches[2] && measureTab !== 'mask' ? String(bleaches[2]) : '',
        color2: color2,
        blch3: bleaches[3] && measureTab !== 'mask' ? String(bleaches[3]) : '',
        color3: color3,
        step_blch: totalBleach ? String(totalBleach) : '',
        control_size_mm: String(measurements[1] / measurementMultiplier),
        width_size_mm: measurements[2] ? String(measurements[2]) : '',
        heigth_size_mm: measurements[3] ? String(measurements[3]) : '',
        bold_size_mm: measurements[4] ? String(measurements[4]) : '',
        project: currentProjectName,
        status: 'inwork'
      },
      newFile: {
        id: activeFile.id,
        bleaches: {
          1: bleaches[1] ? String(bleaches[1]) : '',
          number1: String(pickSizes[1]),
          2: bleaches[2] ? String(bleaches[2]) : '',
          3: bleaches[3] ? String(bleaches[3]) : '',
          total: totalBleach ? String(totalBleach) : ''
        },
        measurements: {
          1: String(measurements[1] / measurementMultiplier),
          2: measurements[2] ? String(measurements[2]) : '',
          3: measurements[3] ? String(measurements[3]) : '',
          4: measurements[4] ? String(measurements[4]) : ''
        },
        colors: {
          1: color1,
          2: color2,
          3: color3
        },
        project: currentProjectName,
        status: 'inwork'
      }
    };
  };

  const onSubmit: () => void = async () => {
    if (!uploaded || !saved) return;

    try {
      const body = construct().requestBody;
      const activeFileIdx =
        activeFile.status === 'new'
          ? uploaded.findIndex((f) => f.id === activeFile.id)
          : saved.findIndex((f) => f.id === activeFile.id);

      await defaultApi.update(body);
      toast.success('Updated successfully');
      activeFile.status === 'new'
        ? activeFileIdx !== uploaded.length - 1
          ? dispatch(fetchUploadedThunk(uploaded[activeFileIdx + 1]))
          : dispatch(setActiveFile(undefined))
        : activeFileIdx !== saved.length - 1
          ? dispatch(fetchSavedThunk(saved[activeFileIdx + 1]))
          : dispatch(setActiveFile(undefined));

      onRefresh();
    } catch (err) {
      console.log(err);
    }
  };

  const onRefresh = () => {
    if (activeFile?.status === 'new') {
      dispatch(
        removeProjectData({
          project: currentProjectName,
          type: 'new_data',
          ids: [activeFile.id]
        })
      );
      dispatch(
        addProjectData({
          project: currentProjectName,
          type: 'inwork_data',
          data: [{ id: activeFile.id, name: activeFile.name }]
        })
      );
    }
  };

  const onDelete = async () => {
    try {
      await defaultApi.delete(activeFile.id);

      dispatch(
        removeProjectData({
          project: currentProjectName,
          type: activeFile?.status === 'new' ? 'new_data' : 'inwork_data',
          ids: [activeFile.id]
        })
      );
      toast.success('Image has been deleted');
    } catch (err) {
      console.log(err);
      toast.error('Error deleting image');
    } finally {
      setDeleteModalOpen(false);
    }
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this image?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
          <Button onClick={onDelete} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Stack sx={{ padding: '16px 10px' }}>
        <Stack direction='row' alignItems='center' sx={{ marginBottom: '8px' }}>
          <Typography
            sx={{
              cursor: tab === 'metadata' ? 'default' : 'pointer',
              color: tab === 'metadata' ? 'black' : 'grey',
              paddingRight: '10px',
              textDecoration: tab === 'metadata' ? 'underline' : 'none'
            }}
            onClick={() => setTab('metadata')}
          >
            Metadata
          </Typography>
          <Typography>|</Typography>
          <Typography
            sx={{
              cursor: tab === 'presets' ? 'default' : 'pointer',
              color: tab === 'presets' ? 'black' : 'grey',
              paddingInline: '10px',
              textDecoration: tab === 'presets' ? 'underline' : 'none'
            }}
            onClick={() => setTab('presets')}
          >
            Presets
          </Typography>
          <Typography>|</Typography>
          <Typography
            sx={{
              cursor: tab === 'bleach' ? 'default' : 'pointer',
              color: tab === 'bleach' ? 'black' : 'grey',
              paddingInline: '10px',
              textDecoration: tab === 'bleach' ? 'underline' : 'none'
            }}
            onClick={() => setTab('bleach')}
          >
            Bleach params
          </Typography>
          <Typography>|</Typography>
          <Typography
            sx={{
              cursor: tab === 'shortcuts' ? 'default' : 'pointer',
              color: tab === 'shortcuts' ? 'black' : 'grey',
              paddingInline: '10px',
              textDecoration: tab === 'shortcuts' ? 'underline' : 'none'
            }}
            onClick={() => setTab('shortcuts')}
          >
            Shortcuts
          </Typography>
        </Stack>
        {tab === 'metadata' && (
          <>
            {activeFile?.file ? (
              <Stack gap={2}>
                <Stack
                  direction='row'
                  gap={2}
                  justifyContent={'space-between'}
                  alignItems={'flex-end'}
                >
                  <Stack direction='row' gap={2}>
                    <Typography>Name:</Typography>
                    <Typography
                      sx={{
                        color: '#626262',
                        width: '200px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                      }}
                    >
                      {activeFile?.name}
                    </Typography>
                  </Stack>
                  {activeFile?.file && (
                    <Stack
                      direction='row'
                      alignItems='center'
                      gap={1}
                      onClick={() => setDeleteModalOpen(true)}
                      sx={{
                        mt: 2,
                        cursor: 'pointer',
                        padding: '2px 8px',
                        borderRadius: 2,
                        width: 'fit-content',
                        transition: '0.2s',
                        '&:hover': { backgroundColor: '#f1f1f1' }
                      }}
                    >
                      <DeleteOutlineIcon
                        sx={{ color: 'blue', height: '16px', width: '16px' }}
                      />
                      <Typography
                        sx={{ color: 'blue', fontSize: 14, fontWeight: 500 }}
                      >
                        Delete
                      </Typography>
                    </Stack>
                  )}
                </Stack>
                <Stack direction='row' gap={2}>
                  <Typography>Date:</Typography>
                  <Typography sx={{ color: '#626262' }}>
                    {moment(activeFile?.date, 'YYYY-MM-DD').format(
                      'DD.MM.YYYY'
                    )}
                  </Typography>
                </Stack>
                <Stack direction='row' gap={2}>
                  <Typography>Location:</Typography>
                  <Typography sx={{ color: '#626262' }}>
                    {activeFile?.location}
                  </Typography>
                </Stack>
                <Stack direction='row' gap={2}>
                  <Typography>Experiment number:</Typography>
                  <Typography sx={{ color: '#626262' }}>
                    {activeFile?.experiment}
                  </Typography>
                </Stack>
                <Stack direction='row' gap={2}>
                  <Typography>Frame:</Typography>
                  <Typography sx={{ color: '#626262' }}>
                    {activeFile?.frame}
                  </Typography>
                </Stack>
                <Stack direction='row' gap={2}>
                  <Typography>Comment#1:</Typography>
                  <Typography sx={{ color: '#626262' }}>
                    {activeFile?.comment1}
                  </Typography>
                </Stack>
                <Stack direction='row' gap={2}>
                  <Typography>Comment#2:</Typography>
                  <Typography sx={{ color: '#626262' }}>
                    {activeFile?.comment2}
                  </Typography>
                </Stack>
              </Stack>
            ) : (
              <Box>
                <Typography sx={{ color: '#C1C1C1' }}>Upload photo</Typography>
              </Box>
            )}
          </>
        )}
        {tab === 'presets' && <Presets />}
        {tab === 'bleach' && <Settings />}
        {tab === 'shortcuts' && (
          <Stack gap={1}>
            <Typography sx={{ marginBottom: 1 }}>
              <b>Workspace:</b>
            </Typography>
            <Typography>
              1. <b>Shift + 1, 2, 3</b> - bleaching 1, 2, 3
            </Typography>
            <Typography>
              2. <b>Ctrl + 1, 2, 3, 4</b> - measurements 1, 2, 3, 4
            </Typography>
            <Typography>
              3. <b>Shift + Tab</b> - switch between 'Dot' and 'Mask'
            </Typography>
            <Typography>
              4. <b>Shift + Enter</b> - update file
            </Typography>
            <Typography>
              5. <b>a / w</b> - choose previous file, <b>d / s</b> - choose next
              file
            </Typography>
            <Typography>
              6. <b>Shift + z, x, c</b> - presets 1, 2, 3
            </Typography>
            <ManualLink />
          </Stack>
        )}
      </Stack>
      <Divider />
      <TransformImage />
      <Divider />
      <Stack>
        <Stack justifyContent='space-between' direction='row'>
          <Stack direction='row' alignItems='center'>
            <Typography
              sx={{
                cursor: measureTab === 'dot' ? 'default' : 'pointer',
                padding: '16px 10px',
                color: measureTab === 'dot' ? 'black' : 'grey',
                textDecoration: measureTab === 'dot' ? 'underline' : 'none'
              }}
              onClick={() => setMeasureTab('dot')}
            >
              Dot
            </Typography>
            <Typography>|</Typography>
            <Typography
              sx={{
                cursor: measureTab === 'mask' ? 'default' : 'pointer',
                padding: '16px 10px',
                color: measureTab === 'mask' ? 'black' : 'grey',
                textDecoration: measureTab === 'mask' ? 'underline' : 'none'
              }}
              onClick={() => setMeasureTab('mask')}
            >
              Mask
            </Typography>
          </Stack>
          <Stack
            direction='row'
            gap={2}
            alignItems='center'
            sx={{ padding: 1 }}
          >
            <Button
              size='small'
              id='btn__clean'
              sx={{ padding: 2, fontSize: 14 }}
            >
              Clean
            </Button>
            <Button
              sx={{ padding: 2, minWidth: 'unset', borderRadius: 4 }}
              onClick={() => setTab('bleach')}
            >
              <SettingsIcon sx={{ color: '#0038FF', width: 20, height: 20 }} />
            </Button>
          </Stack>
        </Stack>
        {measureTab === 'dot' && (
          <Stack>
            <BleachRow idx={1} />
            <BleachRow idx={2} />
            <BleachRow idx={3} />
            <Stack
              direction='row'
              alignItems='center'
              gap={2}
              sx={{ padding: '6px 10px' }}
            >
              <Typography sx={{ width: 100 }}>Bleaching:</Typography>
              <TextField
                size='small'
                sx={{
                  width: 70,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderRadius: '6px',
                  borderColor: 'transparent'
                }}
                value={totalBleach ?? ''}
                disabled
              />
            </Stack>
          </Stack>
        )}
        {measureTab === 'mask' && (
          <Stack>
            <Stack
              direction='row'
              alignItems='center'
              gap={2}
              sx={{
                backgroundColor:
                  measureMode === MeasureMode.Mask ? '#0000FF1A' : 'unset',
                padding: '6px 10px'
              }}
            >
              <Typography sx={{ width: 100 }}>Size:</Typography>
              <TextField
                size='small'
                sx={{
                  width: 70,
                  borderRadius: '4px'
                }}
                type='number'
                inputProps={{
                  min: 0
                }}
                value={maskSize}
                disabled={Boolean(activeFile?.transformedFile) || !activeFile}
                onChange={(e) => dispatch(setMaskSize(Number(e.target.value)))}
              />
              <ActionButton
                onClick={() =>
                  measureMode !== MeasureMode.Mask
                    ? dispatch(setMeasureMode(MeasureMode.Mask))
                    : dispatch(setMeasureMode(MeasureMode.Unset))
                }
                image='assets/brush.svg'
                disabled={Boolean(activeFile?.transformedFile) || !activeFile}
                active={measureMode === MeasureMode.Mask}
              />
              {maskColor && (
                <Box
                  sx={{
                    borderRadius: '6px',
                    width: 20,
                    height: 20,
                    backgroundColor: `rgb(${maskColor?.r}, ${maskColor?.g}, ${maskColor?.b})`
                  }}
                />
              )}
            </Stack>
            <Stack
              direction='row'
              alignItems='center'
              gap={2}
              sx={{
                backgroundColor:
                  measureMode === MeasureMode.MaskEraser
                    ? '#0000FF1A'
                    : 'unset',
                padding: '6px 10px'
              }}
            >
              <Typography sx={{ width: 100 }}>Eraser:</Typography>
              <TextField
                size='small'
                sx={{
                  width: 70,
                  borderRadius: '4px'
                }}
                type='number'
                inputProps={{
                  min: 0
                }}
                value={maskEraserSize}
                disabled={Boolean(activeFile?.transformedFile) || !activeFile}
                onChange={(e) =>
                  dispatch(setMaskEraserSize(Number(e.target.value)))
                }
              />
              <ActionButton
                onClick={() =>
                  measureMode !== MeasureMode.MaskEraser
                    ? dispatch(setMeasureMode(MeasureMode.MaskEraser))
                    : dispatch(setMeasureMode(MeasureMode.Unset))
                }
                image='assets/eraser.svg'
                disabled={Boolean(activeFile?.transformedFile) || !activeFile}
                active={measureMode === MeasureMode.MaskEraser}
              />
            </Stack>
            <Stack
              direction='row'
              alignItems='center'
              gap={2}
              sx={{ padding: '6px 10px' }}
            >
              <Typography sx={{ width: 100 }}>Bleaching:</Typography>
              <TextField
                size='small'
                sx={{
                  width: 70,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderRadius: '6px',
                  borderColor: 'transparent'
                }}
                value={totalBleach ?? ''}
                disabled
              />
            </Stack>
          </Stack>
        )}
        <Divider sx={{ width: 'calc(100% - 28px)', margin: '12px auto' }} />
        <Stack>
          <Stack
            direction='row'
            gap={1}
            sx={{ p: 2, cursor: 'pointer' }}
            onClick={() => setMeasurementsOpen(!measurementsOpen)}
          >
            <Typography>Measurements</Typography>
            <KeyboardArrowDownIcon
              sx={{
                transform: measurementsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: '0.2s'
              }}
            />
          </Stack>
          {measurementsOpen && (
            <>
              <MeasurementRow idx={1} subtitle={'control'} />
              <MeasurementRow idx={2} subtitle={'width'} />
              <MeasurementRow idx={3} subtitle={'height'} />
              <MeasurementRow idx={4} subtitle={'thickness'} />
            </>
          )}
        </Stack>
        <Divider sx={{ width: 'calc(100% - 28px)', margin: '12px auto' }} />
        <Button
          variant='contained'
          onClick={onSubmit}
          sx={{ margin: '24px 10px 16px' }}
        >
          <Stack direction='row' alignItems='center' gap={2}>
            <img src='/assets/update.svg' style={{ width: '10px' }} />
            Update
          </Stack>
        </Button>
      </Stack>
    </Stack>
  );
};

export default WorkspaceRight;
