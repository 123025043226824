export default {
  set(key: string, value: string, session?: boolean) {
    const storage = session ? sessionStorage : localStorage;

    try {
      storage.setItem(key, JSON.stringify(value));
    } catch (err) {
      console.log('Error during setting to storage: ', err);
      throw err;
    }
  },
  get(key: string, session?: boolean) {
    const storage = session ? sessionStorage : localStorage;

    try {
      const data = storage.getItem(key);

      return data ? JSON.parse(data) : null;
    } catch (err) {
      console.log('Error during getting from storage: ', err);
      throw err;
    }
  },
  remove(key: string, session?: boolean) {
    const storage = session ? sessionStorage : localStorage;

    try {
      storage.removeItem(key);
    } catch (err) {
      console.log('Error during removing from storage: ', err);
      throw err;
    }
  }
};