import { API } from "./index";
import { ENDPOINTS } from "../const/endpoints";

type ImportBody = {
  files: File[];
  loc: string;
  date: string;
  exp: string;
  ram: string;
  com1?: string;
  com2?: string;
  project: string;
}

type ImportApi = {
  upload: (body: ImportBody) => Promise<{_id: string; name: string}[]>
}

export const importApi: ImportApi = {
  upload(body: ImportBody) {
    const formData = new FormData();
    formData.append('date', body.date);
    formData.append('loc', body.loc);
    formData.append('com1', body.com1 ?? '');
    formData.append('com2', body.com2 ?? '');
    formData.append('exp', body.exp);
    formData.append('ram', body.ram);
    formData.append('project', body.project);
    body.files.forEach((item) => formData.append("files", item));

    return API.post(ENDPOINTS.IMPORT.UPLOAD, formData, { headers: { 'Content-type': 'multipart/form-data' } });
  }
};
