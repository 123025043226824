import { AppDispatch } from 'store';
import { FileT } from 'types/global';
import { setActiveFile, setLoading } from './globalSlice';
import { defaultApi } from 'api/default';
import {
  resetBounds,
  resetMeasurements,
  setBleaches,
  setColor,
  setMaskColor,
  setMeasurements,
  setTotalBleach
} from './measurementSlice';
import { setCurrentPreset, setPresets } from './presetSlice';
import { presetApi } from 'api/presets';

export const updateActiveFileThunk =
  (file: FileT) => (dispatch: AppDispatch) => {
    dispatch(setActiveFile(file));
    dispatch(resetMeasurements());

    if (file.bleaches) {
      dispatch(
        setBleaches({
          1: Number(file.bleaches[1]),
          2: Number(file.bleaches[2]),
          3: Number(file.bleaches[3])
        })
      );
      if (file.bleaches['total'])
        dispatch(setTotalBleach(Number(file.bleaches['total'])));
    }

    if (file.measurements) {
      dispatch(
        setMeasurements({
          1: Number(file.measurements[1]),
          2: file.measurements[2] ? Number(file.measurements[2]) : undefined,
          3: file.measurements[3] ? Number(file.measurements[3]) : undefined,
          4: file.measurements[4] ? Number(file.measurements[4]) : undefined
        })
      );
    }

    if (file.colors) {
      if (file.colors?.[1]) {
        const [color1_r, color1_g, color1_b] = file.colors[1].split(',');

        if (file.bleaches?.[1]) {
          dispatch(
            setColor({
              idx: 1,
              value: {
                r: Number(color1_r),
                g: Number(color1_g),
                b: Number(color1_b)
              }
            })
          );
        } else {
          dispatch(
            setMaskColor({
              r: Number(color1_r),
              g: Number(color1_g),
              b: Number(color1_b)
            })
          );
        }
      }
      if (file.colors?.[2]) {
        const [color2_r, color2_g, color2_b] = file.colors[2].split(',');
        dispatch(
          setColor({
            idx: 2,
            value: {
              r: Number(color2_r),
              g: Number(color2_g),
              b: Number(color2_b)
            }
          })
        );
      }
      if (file.colors?.[3]) {
        const [color3_r, color3_g, color3_b] = file.colors[3].split(',');
        dispatch(
          setColor({
            idx: 3,
            value: {
              r: Number(color3_r),
              g: Number(color3_g),
              b: Number(color3_b)
            }
          })
        );
      }
    }
  };

export const fetchUploadedThunk =
  (file: FileT) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      type ReturnT = {
        info: {
          ram: string;
          com1: string;
          com2: string;
          loc: string;
          name: string;
          date: string;
          exp: string;
          project: string;
        };
        pic: string;
      };
      const response = (await defaultApi.get(file.id)) as ReturnT;
      const { ram, com1, com2, loc, date, name, exp, project } = response.info;

      const fetchedFile = {
        id: file.id,
        file: response.pic,
        frame: ram,
        name: name,
        experiment: exp,
        date: date,
        comment1: com1,
        comment2: com2,
        location: loc,
        project: project,
        status: 'new'
      } as FileT;
      dispatch(updateActiveFileThunk(fetchedFile));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const fetchSavedThunk =
  (file: FileT) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      type ReturnT = {
        info: {
          ram: string;
          com1: string;
          com2: string;
          loc: string;
          name: string;
          date: string;
          exp: string;
          project: string;
          blch1?: string;
          blch1_number?: string;
          blch2?: string;
          blch3?: string;
          bold_size_mm?: string;
          color1?: string;
          color2?: string;
          color3?: string;
          control_size_mm?: string;
          heigth_size_mm?: string;
          step_blch?: string;
          width_size_mm?: string;
        };
        pic: string;
      };
      const response = (await defaultApi.get(file.id)) as ReturnT;
      const {
        ram,
        com1,
        com2,
        loc,
        date,
        name,
        exp,
        project,
        color1,
        color2,
        color3,
        blch1,
        blch2,
        blch3,
        step_blch,
        blch1_number,
        bold_size_mm,
        width_size_mm,
        heigth_size_mm,
        control_size_mm
      } = response.info;

      const fetchedFile = {
        id: file.id,
        file: response.pic,
        frame: ram,
        name: name,
        experiment: exp,
        date: date,
        comment1: com1,
        comment2: com2,
        location: loc,
        project: project,
        status: 'inwork',
        colors: {
          1: color1,
          2: color2,
          3: color3
        },
        bleaches: {
          1: blch1,
          2: blch2,
          3: blch3,
          total: step_blch,
          number1: blch1_number
        },
        measurements: {
          1: control_size_mm,
          2: width_size_mm,
          3: heigth_size_mm,
          4: bold_size_mm
        }
      } as FileT;
      dispatch(updateActiveFileThunk(fetchedFile));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const resetPreset = () => (dispatch: AppDispatch) => {
  dispatch(setCurrentPreset(null));
  dispatch(resetBounds());
};

export const fetchPresets = () => async (dispatch: AppDispatch) => {
  try {
    const presets = await presetApi.getAll();
    dispatch(setPresets(presets));
    if (presets.length) setCurrentPreset(null);
  } catch (e) {
    console.log(e);
  }
};
