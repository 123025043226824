import userReducer from "./userSlice";
import importReducer from "./importSlice";
import exportReducer from "./exportSlice";
import globalReducer from "./globalSlice";
import measurementReducer from "./measurementSlice";
import presetReducer from "./presetSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const userPersistConfig = {
    key: 'user',
    storage
};

const rootReducer = combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    import: importReducer,
    export: exportReducer,
    global: globalReducer,
    measurement: measurementReducer,
    preset: presetReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector = useSelector.withTypes<RootState>();

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
    })
});

const persistor = persistStore(store);

export { store, persistor };
