import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import {
  CoordT,
  FileT,
  ImageT,
  LineT,
  MeasureMode,
  PointT
} from '../../types/global';
import {
  getActiveFile,
  getMeasureMode,
  setLoading
} from '../../store/globalSlice';
import {
  getBleachBounds,
  getMaskSize,
  getMeasurementMultiplier,
  getMeasurements,
  getPickSizes,
  setBleach,
  setColor,
  setMaskColor,
  setMeasurement,
  setTotalBleach
} from '../../store/measurementSlice';
import _ from 'lodash';
import {
  calculateDistance_rel,
  calculateFitSize,
  calculateOffsets,
  cleanCanvas_rel,
  isMouseInImg,
  redrawImage
} from '../../lib/canvas';
import {
  drawAllLines,
  drawLine_rel,
  translateAllLines
} from '../../lib/canvas/line';
import {
  drawAllPoints,
  drawPoint,
  drawPoint_rel,
  translateAllPoints
} from '../../lib/canvas/point';
import { drawMask, translateMask } from '../../lib/canvas/mask';
import { getBleachValue } from '../../lib/measurements/bleach';

const CANVAS_PADDING = 10;
const LEFT_MENU_WIDTH = 370;
const RIGHT_MENU_WIDTH = 350;

const IMAGE_DEFAULT = { x: 0, y: 0, width: 0, height: 0, source: undefined };

const CanvasWidget: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [canvas, setCanvas] = useState<HTMLCanvasElement>();
  const [ctx, setCtx] = useState<CanvasRenderingContext2D>();
  const [canvasWidth, setCanvasWidth] = useState<number>(0);
  const [canvasHeight, setCanvasHeight] = useState<number>(0);

  let mouseX = 0;
  let mouseY = 0;
  let isDragging: boolean = false;
  let isMasking: boolean = false;
  let currentPointType: 'start' | 'end' = 'start';
  const currentLine: LineT = { start: { x: 0, y: 0 }, end: { x: 0, y: 0 } };

  // TODO: make a structure for this
  const [mask, setMask] = useState<Set<string>>(new Set([]));
  const [maskRgb, setMaskRgb] = useState<[number, number, number]>([0, 0, 0]);
  const [maskBleach, setMaskBleach] = useState<number>(0);

  let buf_mask: Set<string> = new Set([]);
  let buf_maskRgb = [0, 0, 0];
  let buf_maskBleach = 0;

  let buf_image: ImageT = IMAGE_DEFAULT;
  const [image, setImage] = useState<ImageT>(IMAGE_DEFAULT);

  let buf_zoom = 1;
  const [zoom, setZoom] = useState<number>(1);

  let buf_points: PointT[] = [];
  const [points, setPoints] = useState<PointT[]>([]);

  let buf_lines: LineT[] = [];
  const [lines, setLines] = useState<LineT[]>([]);

  const pickSizes = useSelector(getPickSizes);
  const maskSize = useSelector(getMaskSize);
  const measurements = useSelector(getMeasurements);
  const controlMeasure = measurements?.[1];
  const multiplier = useSelector(getMeasurementMultiplier);
  const activeFile = useSelector(getActiveFile) as FileT;
  const measureMode = useSelector(getMeasureMode);
  const bounds = useSelector(getBleachBounds);
  const control = multiplier / controlMeasure;

  const onSetZoom = (zoom: number) => {
    buf_zoom = zoom;
    setZoom(zoom);
  };

  const onSetPoints = (points: PointT[]) => {
    buf_points = points;
    setPoints(points);
  };

  const onSetLines = (lines: LineT[]) => {
    buf_lines = lines;
    setLines(lines);
  };

  const onSetImage = (image: ImageT) => {
    buf_image = image;
    setImage(image);
  };

  const onSetMask = (mask: Set<string>) => {
    buf_mask = mask;
    setMask(mask);
  };

  const onSetMaskRgb = (maskRgb: [number, number, number]) => {
    buf_maskRgb = maskRgb;
    setMaskRgb(maskRgb);
  };

  const onSetMaskBleach = (maskBleach: number) => {
    buf_maskBleach = maskBleach;
    setMaskBleach(maskBleach);
  };

  const cleanMask = () => {
    onSetMask(new Set([]));
    onSetMaskRgb([0, 0, 0]);
    onSetMaskBleach(0);
  };

  // Is executed on first render
  // Init canvas & context
  useEffect(() => {
    const buf_canvas = document.getElementById(
      'workspace'
    ) as HTMLCanvasElement;
    setCanvas(buf_canvas);

    const buf_ctx = buf_canvas.getContext('2d') as CanvasRenderingContext2D;
    setCtx(buf_ctx);

    initCanvas({ canvas: buf_canvas, ctx: buf_ctx });
    window.addEventListener('resize', () =>
      initCanvas({ canvas: buf_canvas, ctx: buf_ctx })
    );

    resetBufValues();
  }, [activeFile]);

  const resetBufValues = () => {
    onSetZoom(1);
    onSetLines([]);
    onSetPoints([]);
    onSetImage(IMAGE_DEFAULT);
    cleanMask();
  };

  const initBufValues = () => {
    if (!ctx) return;

    buf_zoom = zoom;

    buf_points = points;
    drawAllPoints({ ctx, points: buf_points });

    buf_lines = lines;
    drawAllLines({ ctx, lines: buf_lines });

    buf_image = image;

    buf_mask = mask;
    buf_maskRgb = maskRgb;
    buf_maskBleach = maskBleach;
    drawMask({ ctx, mask: buf_mask });
  };

  // Sets canvas width and height, fills with grey and draws image if needed
  const initCanvas = ({
    canvas,
    ctx
  }: {
    canvas?: HTMLCanvasElement;
    ctx?: CanvasRenderingContext2D;
  }) => {
    if (!ctx || !canvas) return;

    canvas.width = window.innerWidth - LEFT_MENU_WIDTH - RIGHT_MENU_WIDTH;
    canvas.height = window.innerHeight;
    setCanvasHeight(canvas.height);
    setCanvasWidth(canvas.width);

    cleanCanvas_rel({
      ctx,
      width: canvas.width,
      height: canvas.height,
      zoom: zoom
    });

    redrawImage({ ctx, canvasWidth, canvasHeight, image: buf_image });
  };

  // Is executed on first render & when activeFile | mode | pickSizes is changed
  // Inits active image
  useEffect(() => {
    dispatch(setLoading(true));

    const images = document.getElementsByTagName('img');
    if (images)
      for (const i of images) {
        i.onload = null;
      }

    const activeImg = document.getElementById('active-img') as HTMLImageElement;
    const activeImg_transformed = document.getElementById(
      'active-img__transformed'
    ) as HTMLImageElement;

    if (activeImg)
      activeImg.addEventListener('load', () => onImageLoad(activeImg));
    if (activeImg_transformed)
      activeImg_transformed.addEventListener('load', () =>
        onImageLoad(activeImg_transformed)
      );

    if (activeImg && activeImg_transformed) {
      onImageLoad(activeImg_transformed);
    } else if (activeImg && !activeImg_transformed) {
      onImageLoad(activeImg);
    }

    if (!activeFile) dispatch(setLoading(false));
  }, [activeFile, measureMode, pickSizes, maskSize]);

  // Zoom, zoom in - coefficient > 1, zoom out - coefficient < 1
  const onZoom = (e: WheelEvent) => {
    if (!ctx || !canvas) return;

    e.preventDefault();

    let coefficient = 1;
    const delta = 0.05;
    if (e.deltaY > 0) {
      coefficient -= delta;
    } else {
      coefficient += delta;
    }

    onSetZoom(buf_zoom * coefficient);

    const { offsetX, offsetY } = calculateOffsets(canvas);

    mouseX = (e.clientX - offsetX) / buf_zoom;
    mouseY = (e.clientY - offsetY) / buf_zoom;

    const diffX = mouseX - mouseX * coefficient;
    const diffY = mouseY - mouseY * coefficient;

    const newImage = {
      ...buf_image,
      x: buf_image.x + diffX,
      y: buf_image.y + diffY
    };
    onSetImage(newImage);

    cleanCanvas_rel({
      ctx,
      width: canvasWidth,
      height: canvasHeight,
      zoom: buf_zoom
    });
    redrawImage({
      ctx,
      canvasHeight,
      canvasWidth,
      image: buf_image,
      zoom: coefficient
    });
    translateAllPoints({
      ctx,
      dx: diffX,
      dy: diffY,
      setPoints: onSetPoints,
      points: buf_points
    });
    translateAllLines({
      ctx,
      dx: diffX,
      dy: diffY,
      setLines: onSetLines,
      lines: buf_lines
    });
    translateMask({
      ctx,
      dx: diffX,
      dy: diffY,
      setMask: onSetMask,
      mask: buf_mask
    });
  };

  // Sets image when it is loaded
  const onImageLoad = (img: HTMLImageElement) => {
    const { imgWidth, imgHeight } = calculateFitSize({
      padding: CANVAS_PADDING,
      image: img,
      canvasWidth,
      canvasHeight
    });

    if (!imgWidth || !imgHeight || !ctx) return;

    dispatch(setLoading(false));

    mouseX = CANVAS_PADDING;
    mouseY = CANVAS_PADDING;
    let width = imgWidth;
    let height = imgHeight;

    // if image didn't change
    if (img === image.source) {
      mouseX = image.x;
      mouseY = image.y;
      width = image.width;
      height = image.height;
    } else {
      resetBufValues();
    }

    onSetImage({
      x: mouseX,
      y: mouseY,
      width: width,
      height: height,
      source: img as CanvasImageSource
    });
    redrawImage({ ctx, canvasWidth, canvasHeight, image: buf_image });

    if (img === image.source) {
      initBufValues();
    }

    updateListeners();
  };

  // Listener for mouse down
  const mouseDown = function (e: MouseEvent) {
    if (!canvas) return;

    e.preventDefault();

    const { offsetX, offsetY } = calculateOffsets(canvas);
    mouseX = e.clientX - offsetX;
    mouseY = e.clientY - offsetY;
    updateListeners();

    if (isMouseInImg({ mouseX, mouseY, image: buf_image, zoom: buf_zoom })) {
      if (
        measureMode === MeasureMode.ColorPick1 ||
        measureMode === MeasureMode.ColorPick2 ||
        measureMode === MeasureMode.ColorPick3
      ) {
        setColorAndBleach();
      }

      if (
        measureMode === MeasureMode.Measure1 ||
        measureMode === MeasureMode.Measure2 ||
        measureMode === MeasureMode.Measure3 ||
        measureMode === MeasureMode.Measure4
      ) {
        measure();
      }

      if (
        measureMode === MeasureMode.Mask ||
        measureMode === MeasureMode.MaskEraser
      ) {
        isMasking = true;
        setColorAndBleach();
      }

      if (measureMode === MeasureMode.Unset) {
        isDragging = true;
      }

      return;
    }
  };

  // Listener for mouse up
  const mouseUp = (e: MouseEvent) => {
    if (!(isDragging || isMasking)) return;

    e.preventDefault();
    isDragging = false;
    isMasking = false;
  };

  // Listener for mouse move
  const mouseMove = (e: MouseEvent) => {
    if (!ctx || !canvas || !(isMasking || isDragging)) return;

    e.preventDefault();

    const { offsetX, offsetY } = calculateOffsets(canvas);

    const newMouseX = e.clientX - offsetX;
    const newMouseY = e.clientY - offsetY;

    // velocity
    const dx_rel = (newMouseX - mouseX) / buf_zoom;
    const dy_rel = (newMouseY - mouseY) / buf_zoom;

    mouseX = newMouseX;
    mouseY = newMouseY;

    if (isDragging) {
      onSetImage({
        ...buf_image,
        x: buf_image.x + dx_rel,
        y: buf_image.y + dy_rel
      });
      cleanCanvas_rel({
        ctx,
        width: canvasWidth,
        height: canvasHeight,
        zoom: buf_zoom
      });
      redrawImage({ ctx, canvasWidth, canvasHeight, image: buf_image });
      translateAllPoints({
        ctx,
        dx: dx_rel,
        dy: dy_rel,
        setPoints: onSetPoints,
        points: buf_points
      });
      translateAllLines({
        ctx,
        dx: dx_rel,
        dy: dy_rel,
        setLines: onSetLines,
        lines: buf_lines
      });
      translateMask({
        ctx,
        dx: dx_rel,
        dy: dy_rel,
        mask: buf_mask,
        setMask: onSetMask
      });
      currentPointType = 'start';

      updateListeners();
    }

    if (
      isMasking &&
      isMouseInImg({ mouseX, mouseY, image: buf_image, zoom: buf_zoom })
    ) {
      setColorAndBleach();
    }
  };

  // Update all event listeners with new image & positions
  const updateListeners = () => {
    if (!canvas || !ctx) return;

    canvas.onmousedown = (e) => mouseDown(e);
    canvas.onmouseup = mouseUp;
    canvas.onmouseout = mouseUp;
    canvas.onmousemove = (e) => mouseMove(e);
    canvas.onwheel = (e) => onZoom(e);

    window.addEventListener('resize', () => initCanvas({ canvas, ctx }));

    const cleanButton = document.getElementById('btn__clean') as HTMLElement;
    cleanButton.onmousedown = () => {
      onSetPoints([]);
      onSetLines([]);
      redrawImage({ ctx, canvasWidth, canvasHeight, image: buf_image });
      drawAllPoints({ ctx, points: buf_points });
      drawAllLines({ ctx, lines: buf_lines });
      cleanMask();
      drawMask({ ctx, mask: buf_mask });
    };
  };

  // Gets and sets color for chosen row
  const setColorAndBleach = () => {
    if (!ctx) return;

    let idx: 1 | 2 | 3;
    let data: { rgb: number[]; bleach: number } | undefined = {
      rgb: [0, 0, 0],
      bleach: 0
    };
    if (
      measureMode === MeasureMode.ColorPick1 ||
      measureMode === MeasureMode.ColorPick2 ||
      measureMode === MeasureMode.ColorPick3
    ) {
      switch (measureMode) {
        case MeasureMode.ColorPick1: {
          data = calculateColorFromArea({ size: pickSizes[1] });
          drawPoint_rel({
            ctx,
            x: mouseX,
            y: mouseY,
            size: pickSizes[1],
            zoom: buf_zoom
          });
          onSetPoints([
            ...buf_points,
            { x: mouseX / buf_zoom, y: mouseY / buf_zoom, size: pickSizes[1] }
          ]);
          idx = 1;
          break;
        }
        case MeasureMode.ColorPick2: {
          data = calculateColorFromArea({ size: pickSizes[2] });
          drawPoint_rel({
            ctx,
            x: mouseX,
            y: mouseY,
            size: pickSizes[2],
            zoom: buf_zoom
          });
          onSetPoints([
            ...buf_points,
            { x: mouseX / buf_zoom, y: mouseY / buf_zoom, size: pickSizes[2] }
          ]);
          idx = 2;
          break;
        }
        case MeasureMode.ColorPick3: {
          data = calculateColorFromArea({ size: pickSizes[3] });
          drawPoint_rel({
            ctx,
            x: mouseX,
            y: mouseY,
            size: pickSizes[3],
            zoom: buf_zoom
          });
          onSetPoints([
            ...buf_points,
            { x: mouseX / buf_zoom, y: mouseY / buf_zoom, size: pickSizes[3] }
          ]);
          idx = 3;
          break;
        }
      }

      const r = data?.rgb[0] ?? 0;
      const g = data?.rgb[1] ?? 0;
      const b = data?.rgb[2] ?? 0;

      dispatch(setColor({ idx, value: { r, g, b } }));

      const bleach = data?.bleach ?? 0;
      dispatch(setBleach({ idx, value: bleach }));
    } else if (
      measureMode === MeasureMode.Mask ||
      measureMode === MeasureMode.MaskEraser
    ) {
      data = calculateColorFromMask({ size: maskSize });
      dispatch(setTotalBleach(data?.bleach ?? undefined));
      dispatch(
        setMaskColor(
          data
            ? {
                r: data?.rgb[0] ?? 0,
                g: data?.rgb[1] ?? 0,
                b: data?.rgb[2] ?? 0
              }
            : undefined
        )
      );
    }
  };

  // Calculates and returns medium color and medium bleach for area
  const calculateColorFromArea = (options: { size: number }) => {
    if (!ctx) return;

    const { size } = options;
    const startX = mouseX - size;
    const startY = mouseY - size;

    const rgb = [0, 0, 0];
    let bleach = 0;
    for (let i = 0; i < 2 * size + 1; i++) {
      for (let k = 0; k < 2 * size + 1; k++) {
        const subRes = ctx?.getImageData(startX + i, startY + k, 1, 1).data;
        rgb[0] += subRes[0];
        rgb[1] += subRes[1];
        rgb[2] += subRes[2];
        bleach +=
          getBleachValue({
            color: { r: subRes[0], g: subRes[1], b: subRes[2] },
            bounds
          }) ?? 0;
      }
    }

    const divider = (2 * size + 1) * (2 * size + 1);
    rgb[0] = Math.round(rgb[0] / divider);
    rgb[1] = Math.round(rgb[1] / divider);
    rgb[2] = Math.round(rgb[2] / divider);
    bleach = Math.round(bleach / divider);

    return { rgb, bleach };
  };

  // Calculates and returns medium color and medium bleach for area
  const calculateColorFromMask = (options: { size: number }) => {
    if (!ctx) return;

    const time1 = Date.now();

    const { size } = options;
    const relX = mouseX / buf_zoom;
    const relY = mouseY / buf_zoom;
    const startX = relX - size;
    const startY = relY - size;

    let newCoordsArray: CoordT[];
    let newCoords: Set<CoordT>;
    let diff: Set<CoordT> = new Set([]);

    if (measureMode === MeasureMode.Mask) {
      // coords that were drawn + mask
      newCoordsArray = [...buf_mask];
      for (let i = 0; i < 2 * size; i++) {
        for (let k = 0; k < 2 * size; k++) {
          const x = Math.round(startX + i);
          const y = Math.round(startY + k);
          const point = `${x},${y}`;
          newCoordsArray.push(point);
        }
      }
      newCoords = new Set(newCoordsArray);

      console.log(buf_mask);
      console.log(newCoords);

      // coords that will be drawn
      // mask will not be redrawn
      diff = newCoords.difference(buf_mask);
      onSetMask(newCoords);

      _.each([...diff], (dot: CoordT) => {
        const x = Number(dot.split(',')[0]);
        const y = Number(dot.split(',')[1]);

        drawPoint_rel({
          ctx,
          x,
          y,
          size: 1,
          fillColor: 'rgba(255,17,17,0.1)',
          strokeColor: 'transparent'
        });

        const subRes = ctx?.getImageData(x, y, 1, 1).data;
        onSetMaskRgb([
          buf_maskRgb[0] + subRes[0],
          buf_maskRgb[1] + subRes[1],
          buf_maskRgb[2] + subRes[2]
        ]);
        onSetMaskBleach(
          buf_maskBleach +
            (getBleachValue({
              color: { r: subRes[0], g: subRes[1], b: subRes[2] },
              bounds
            }) ?? 0)
        );
      });
    }

    if (measureMode === MeasureMode.MaskEraser) {
      // coords that were drawn
      newCoordsArray = [];
      for (let i = 0; i < 2 * size; i++) {
        for (let k = 0; k < 2 * size; k++) {
          const x = Math.round(startX + i);
          const y = Math.round(startY + k);
          const point = `${x},${y}`;
          newCoordsArray.push(point);
        }
      }
      newCoords = new Set(newCoordsArray);

      console.log(buf_mask);
      console.log(newCoords);

      // mask - coords that were drawn
      diff = buf_mask.difference(newCoords);
      onSetMask(diff);

      // init canvas, redraw image, redraw mask
      redrawImage({ ctx, canvasWidth, canvasHeight, image: buf_image });
      onSetMaskRgb([0, 0, 0]);
      onSetMaskBleach(0);

      for (const point of [...buf_mask]) {
        const x = Math.round(Number(point.split(',')[0]));
        const y = Math.round(Number(point.split(',')[1]));
        drawPoint({
          ctx,
          x: x,
          y: y,
          size: 1,
          fillColor: 'rgba(255,17,17,0.1)',
          strokeColor: 'transparent'
        });

        const subRes = ctx?.getImageData(x, y, 1, 1).data;
        onSetMaskRgb([
          buf_maskRgb[0] + subRes[0],
          buf_maskRgb[1] + subRes[1],
          buf_maskRgb[2] + subRes[2]
        ]);
        onSetMaskBleach(
          buf_maskBleach +
            (getBleachValue({
              color: { r: subRes[0], g: subRes[1], b: subRes[2] },
              bounds
            }) ?? 0)
        );
      }
    }

    const time2 = Date.now();
    console.log(
      `time: ${(time2 - time1) / 1000} s, length: ${diff.size}, full length: ${buf_mask.size}`
    );

    const divider = buf_mask.size;

    return divider === 0
      ? undefined
      : {
          rgb: [
            Math.round(buf_maskRgb[0] / divider),
            Math.round(buf_maskRgb[1] / divider),
            Math.round(buf_maskRgb[2] / divider)
          ],
          bleach: Math.round(buf_maskBleach / divider)
        };
  };

  // Gets and sets distance for control measurements and actual distances for other measurements
  const measure = () => {
    if (!ctx) return;

    drawPoint_rel({ ctx, x: mouseX, y: mouseY, zoom: buf_zoom });

    let distance: number = 0;
    if (currentPointType === 'end') {
      const newLine = {
        start: {
          x: currentLine.start.x / buf_zoom,
          y: currentLine.start.y / buf_zoom
        },
        end: { x: mouseX / buf_zoom, y: mouseY / buf_zoom }
      };
      buf_lines.push(newLine);
      drawLine_rel({ ctx, line: newLine });
      distance = calculateDistance_rel({
        startX: currentLine.start.x,
        startY: currentLine.start.y,
        endX: mouseX,
        endY: mouseY,
        zoom: buf_zoom
      });
    }

    switch (measureMode) {
      case MeasureMode.Measure1: {
        if (currentPointType === 'end') {
          dispatch(
            setMeasurement({ idx: 1, value: Number(distance.toFixed(2)) })
          );
        }
        break;
      }
      case MeasureMode.Measure2: {
        if (currentPointType === 'end') {
          dispatch(
            setMeasurement({
              idx: 2,
              value: Number((distance * control).toFixed(2))
            })
          );
        }
        break;
      }
      case MeasureMode.Measure3: {
        if (currentPointType === 'end') {
          dispatch(
            setMeasurement({
              idx: 3,
              value: Number((distance * control).toFixed(2))
            })
          );
        }
        break;
      }
      case MeasureMode.Measure4: {
        if (currentPointType === 'end') {
          dispatch(
            setMeasurement({
              idx: 4,
              value: Number((distance * control).toFixed(2))
            })
          );
        }
        break;
      }
      default:
        break;
    }

    if (currentPointType === 'start') {
      currentLine.start = { x: mouseX, y: mouseY };
      currentPointType = 'end';
    } else {
      currentLine.end = { x: mouseX, y: mouseY };
      currentPointType = 'start';
    }
  };

  return <canvas id='workspace' />;
};

export default CanvasWidget;
