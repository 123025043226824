import storage from "../lib/storage";
import axios from "axios";

const setToken = (token) => {
  storage.set('session', token);
  storage.set('sessionCreated', new Date().toUTCString());
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL as string,
  // timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    'X-Frame-Options': 'SAMEORIGIN',
    'Content-Security-Policy': "frame-ancestors 'self'",
  },
});

instance.interceptors.response.use(
  (response) => {
    if (response.data?.tokens?.accessToken)
      setToken('Bearer ' + response.data.tokens.accessToken);

    return response;
  }
);

instance.interceptors.request.use((config) => {
  if (storage.get('session'))
    config.headers.authorization = storage.get('session');

  return config;
});

export const API = {
  async get(url: string, config?) {
    try {
      const { data } = await instance.get(url, config);

      return data;
    } catch (err: any) {
      if (err.response.status === 401 && !err.response.config.url.includes('auth')) {
        window.open('/auth', '_self');
        return;
      }

      console.log('Error during GET request: ', err);
      throw err.response;
    }
  },
  async post(url: string, body, config?) {
    try {
      const { data } = await instance.post(url, body, config);

      return data;
    } catch (err: any) {
      if (err.response.status === 401 && !err.response.config.url.includes('auth')) {
        window.open('/auth', '_self');
        return;
      }

      console.log('Error during POST request: ', err);
      throw err.response;
    }
  },
  async put(url: string, body: object, config?) {
    try {
      const response = await instance.put(url, body, config);

      return { ...response.data, headers: response.headers };
    } catch (err: any) {
      if (err.response.status === 401 && !err.response.config.url.includes('auth')) {
        window.open('/auth', '_self');
        return;
      }

      console.log('Error during PUT request: ', err);
      throw err.response;
    }
  },
  async patch(url: string, body: object, config?) {
    try {
      const response = await instance.patch(url, body, config);

      return { ...response.data, headers: response.headers };
    } catch (err: any) {
      if (err.response.status === 401 && !err.response.config.url.includes('auth')) {
        window.open('/auth', '_self');
        return;
      }

      console.log('Error during PATCH request: ', err);
      throw err.response;
    }
  },
  async delete(url: string, config?) {
    try {
      const response = await instance.delete(url, config);

      return { ...response.data, headers: response.headers };
    } catch (err: any) {
      if (err.response.status === 401 && !err.response.config.url.includes('auth')) {
        window.open('/auth', '_self');
        return;
      }

      console.log('Error during DELETE request: ', err);
      throw err.response;
    }
  },
};
