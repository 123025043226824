import { Bound, ColorT } from '../../types/global';
import * as convert from 'color-convert';

// Calculates bleach value depending on hsv bright
const getStepBleach = ({
  bright,
  bounds
}: {
  bright: number;
  bounds: {
    6: Bound;
    5: Bound;
    4: Bound;
    3: Bound;
    2: Bound;
    1: Bound;
  };
}) => {
  if (bright > 0 && bright < bounds[6].max) return 6;
  if (bright >= bounds[5].min && bright < bounds[5].max) return 5;
  if (bright >= bounds[4].min && bright < bounds[4].max) return 4;
  if (bright >= bounds[3].min && bright < bounds[3].max) return 3;
  if (bright >= bounds[2].min && bright < bounds[2].max) return 2;
  if (bright >= bounds[1].min) return 1;
};

// Returns bleach value depending on hsv bright
export const getBleachValue = ({
  color,
  bounds
}: {
  color: ColorT;
  bounds: {
    6: Bound;
    5: Bound;
    4: Bound;
    3: Bound;
    2: Bound;
    1: Bound;
  };
}) => {
  const hsv = convert.rgb.hsv(color.r, color.g, color.b);
  return getStepBleach({ bright: hsv[2], bounds });
};
