import { API } from "./index";
import { ENDPOINTS } from "../const/endpoints";

export const presetApi = {
  get(id: string) {
    return API.get(ENDPOINTS.PRESETS.GET, { params: { id } });
  },
  getAll() {
    return API.get(ENDPOINTS.PRESETS.GET_ALL);
  },
  create(brightness: number[]) {
    return API.post(ENDPOINTS.PRESETS.CREATE, { brightness });
  },
  update(id: string, brightness: number[]) {
    return API.post(ENDPOINTS.PRESETS.UPDATE, { id, brightness });
  },
  delete(id: string) {
    return API.delete(ENDPOINTS.PRESETS.DELETE, { params: { id } });
  }
};