import { API } from "./index";
import { ENDPOINTS } from "../const/endpoints";

type ExportBody = {
  ids: string[];
  meta: boolean;
  coms: boolean;
  blch: boolean;
  meas: boolean;
}

export const exportApi = {
  export(body: ExportBody) {
    return API.post(ENDPOINTS.EXPORT, body, {responseType: 'blob'});
  }
};