import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField } from "@mui/material";
import { useForm, Controller } from 'react-hook-form';
import { userApi } from "../api/user";
import { useDispatch } from "react-redux";
import storage from "../lib/storage";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { login } from "../store/userSlice";

type FormValues = {
  email: string;
  password: string;
}

const Auth: React.FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control
  } = useForm<FormValues>();

  useEffect(() => {
    const date = Date.parse(storage.get('sessionCreated'));
    if (date) {
      const now = Date.parse((new Date()).toString());
      const diff = now - date;
      const token = storage.get('session');
      if (token && diff < 60 * 60 * 1000)
        dispatch(login());
    }
  }, []);

  const onSubmit = async (data: FormValues) => {
    try {
      await userApi.login(data.email, data.password);
      dispatch(login());
    } catch (err) {
      console.log(err);
      toast.error('User not found');
    }
  };

  return (
    <Box sx={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#ECECEC'
    }}>
      <Stack gap={4} sx={{ width: '450px' }}>
        <Controller
          name='email'
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              label='Email'
              sx={{
                '& .MuiInputBase-root': {
                  padding: '20px 24px !important',
                  borderRadius: '8px'
                },
                '& .MuiInputBase-input': {
                  fontSize: '18px !important',
                },
                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                '& .MuiInputLabel-root': {
                  top: '8px',
                  left: '14px',
                  fontSize: '18px !important',
                },
                '& .MuiInputLabel-shrink': { top: '4px', fontSize: '18px !important', },
              }}
            />
          )}
        />
        <Controller
          name='password'
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormControl variant="outlined" onChange={onChange} onBlur={onBlur}>
              <InputLabel htmlFor="outlined-adornment-password" sx={{
                top: '8px',
                left: '14px',
                fontSize: '18px !important',
                '&.MuiInputLabel-shrink': { top: '4px !important', fontSize: '18px !important', },
              }}>
                Password
              </InputLabel>
              <OutlinedInput
                value={value}
                id="outlined-adornment-password"
                sx={{
                  padding: '20px 24px !important',
                  borderRadius: '8px',
                  '& .MuiInputBase-input': {
                    fontSize: '18px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                }}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(prevState => !prevState)}
                    >
                      {showPassword ? <VisibilityOff sx={{ fontSize: 24 }} /> : <Visibility sx={{ fontSize: 24 }} />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          )}
        />
        <Button
          variant='contained'
          onClick={handleSubmit(onSubmit)}
          sx={{
            padding: '20px 24px !important',
            borderRadius: '8px',
            fontSize: '18px'
          }}
        >
          Send
        </Button>
      </Stack>
    </Box>
  );
};

export default Auth;
