import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from "./AppRouter";

type AuthorizedRouteProps = {
  isAuth: boolean;
  redirectPath?: string;
}

const AuthorizedRoute: React.FC<AuthorizedRouteProps> = ({
  isAuth,
  redirectPath = ROUTES.AUTH.path,
}) => {
  if (!isAuth) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default AuthorizedRoute;
