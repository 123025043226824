import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { Controller, useForm } from 'react-hook-form';
import { importApi } from '../../api/import';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import {
  addProjectData,
  getCurrentProject,
  getProjects,
  getTab,
  setLoading,
  setProjectData
} from '../../store/globalSlice';
import {
  getImportFiles,
  getImportProject,
  removeAllImportFiles,
  setImportProject
} from '../../store/importSlice';
import { Tab } from 'types/global';
import ManualLink from 'ui/ManualLink';

type ImportRightProps = {
  comment?: string;
};

type FormValues = {
  location: string;
  date: string;
  experiment: string;
  frame: string;
  comment1?: string;
  comment2?: string;
};

const ImportRight: React.FC<ImportRightProps> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [tab, setTab] = useState<'metadata' | 'shortcuts'>('metadata');
  const globalTab = useSelector(getTab);
  const files = useSelector(getImportFiles);
  const project = useSelector(getImportProject);
  const currentProject = useSelector(getCurrentProject);
  const projects = useSelector(getProjects);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<FormValues>({
    resolver: yupResolver(
      yup.object().shape({
        experiment: yup.string().required('This field is required'),
        frame: yup.string().required('This field is required'),
        location: yup.string().required('This field is required'),
        date: yup.string().required('This field is required'),
        comment1: yup.string(),
        comment2: yup.string()
      })
    ),
    defaultValues: {
      experiment: '',
      frame: '',
      location: '',
      date: '',
      comment1: '',
      comment2: ''
    }
  });

  useEffect(() => {
    document.onkeydown = (e) => {
      // Shift + Enter
      if (globalTab === Tab.Import && e.shiftKey && e.key === 'Enter') {
        handleSubmit(onSubmit)();
      }

      const activeElement = document.activeElement as HTMLInputElement;
      const date = document.getElementById('input__date') as HTMLInputElement;
      const location = document.getElementById(
        'input__location'
      ) as HTMLInputElement;
      const experiment = document.getElementById(
        'input__experiment'
      ) as HTMLInputElement;
      const frame = document.getElementById('input__frame') as HTMLInputElement;
      const comment1 = document.getElementById(
        'input__comment1'
      ) as HTMLInputElement;
      const comment2 = document.getElementById(
        'input__comment2'
      ) as HTMLInputElement;
      const elements = [date, location, experiment, frame, comment1, comment2];
      const index = activeElement ? elements.indexOf(activeElement) : -1;

      // Shift + Tab
      if (e.shiftKey && e.key === 'Tab') {
        e.preventDefault();
        e.stopPropagation();

        if (index === elements.length - 1 || index === -1) {
          elements[0].focus();
        } else {
          elements[index + 1].focus();
        }
      }

      // Tab
      if (!e.shiftKey && e.key === 'Tab') {
        e.preventDefault();
        e.stopPropagation();

        if (index === -1) {
          elements[0].focus();
        } else if (index === 0) {
          elements[elements.length - 1].focus();
        } else {
          elements[index - 1].focus();
        }
      }
    };
  }, [files, project, globalTab]);

  const onSubmit = async (data: FormValues) => {
    try {
      dispatch(setLoading(true));
      if (files?.length === 0) {
        toast.error('Choose files');
        return;
      }
      if (currentProject === '' && !project) {
        toast.error('Enter project name');
        return;
      }

      const body = {
        files,
        loc: data.location,
        date: data.date,
        exp: data.experiment,
        ram: data.frame,
        com1: data.comment1,
        com2: data.comment2,
        project: currentProject === '' ? project : (currentProject as string)
      };
      const response = await importApi.upload(body);
      toast.success('Imported successfully');
      reset();
      dispatch(removeAllImportFiles());
      dispatch(setImportProject(''));

      // update workspace files
      if (currentProject === '') {
        if (!Object.keys(projects).includes(project))
          dispatch(
            setProjectData({
              project,
              data: {
                new_data: [
                  ...response.map((item) => ({ id: item._id, name: item.name }))
                ]
              }
            })
          );
        else
          dispatch(
            addProjectData({
              project: project,
              type: 'new_data',
              data: [
                ...response.map((item) => ({ id: item._id, name: item.name }))
              ]
            })
          );
      } else {
        dispatch(
          addProjectData({
            project: currentProject as string,
            type: 'new_data',
            data: [
              ...response.map((item) => ({ id: item._id, name: item.name }))
            ]
          })
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Box sx={{ padding: '16px 10px' }}>
      <Stack direction='row' alignItems='center' sx={{ marginBottom: '8px' }}>
        <Typography
          sx={{
            cursor: tab === 'metadata' ? 'default' : 'pointer',
            color: tab === 'metadata' ? 'black' : 'grey',
            paddingRight: '10px',
            textDecoration: tab === 'metadata' ? 'underline' : 'none'
          }}
          onClick={() => setTab('metadata')}
        >
          Meta data
        </Typography>
        <Typography>|</Typography>
        <Typography
          sx={{
            cursor: tab === 'shortcuts' ? 'default' : 'pointer',
            color: tab === 'shortcuts' ? 'black' : 'grey',
            paddingInline: '10px',
            textDecoration: tab === 'shortcuts' ? 'underline' : 'none'
          }}
          onClick={() => setTab('shortcuts')}
        >
          Shortcuts
        </Typography>
      </Stack>
      {tab === 'metadata' && (
        <Stack>
          <Stack direction='row' gap={2}>
            <Typography>Name:</Typography>
            <Box sx={{ overflowY: 'scroll', maxHeight: '30vh', width: '100%' }}>
              <Stack>
                {files.map((file: File, idx) => (
                  <Typography
                    key={idx}
                    sx={{
                      color: '#626262',
                      width: '200px',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden'
                    }}
                  >
                    {file?.name}
                  </Typography>
                ))}
              </Stack>
            </Box>
          </Stack>
          <Stack gap={2} mt={2}>
            <Stack direction='row' gap={2}>
              <Controller
                name='date'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id='input__date'
                    size='small'
                    type='date'
                    label='Date'
                    placeholder='dd.mm.yyyy'
                    value={value}
                    onChange={onChange}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors?.date)}
                    sx={{ flex: 1 }}
                  />
                )}
              />
              <Controller
                name='location'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id='input__location'
                    size='small'
                    label='Location'
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors?.location)}
                    sx={{ flex: 1 }}
                  />
                )}
              />
            </Stack>
            <Stack direction='row' gap={2}>
              <Controller
                name='experiment'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id='input__experiment'
                    size='small'
                    value={value}
                    onChange={onChange}
                    label='Experiment'
                    error={Boolean(errors?.experiment)}
                  />
                )}
              />
              <Controller
                name='frame'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id='input__frame'
                    size='small'
                    value={value}
                    onChange={onChange}
                    label='Frame'
                    error={Boolean(errors?.frame)}
                  />
                )}
              />
            </Stack>
            <Controller
              name='comment1'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id='input__comment1'
                  size='small'
                  value={value}
                  onChange={onChange}
                  label='Comment1'
                  error={Boolean(errors?.comment1)}
                  multiline
                  rows={3}
                />
              )}
            />
            <Controller
              name='comment2'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id='input__comment2'
                  size='small'
                  value={value}
                  onChange={onChange}
                  label='Comment2'
                  error={Boolean(errors?.comment2)}
                  multiline
                  rows={3}
                />
              )}
            />
          </Stack>
          <Button
            variant='contained'
            onClick={handleSubmit(onSubmit)}
            fullWidth
            sx={{ marginTop: '18px' }}
          >
            Send
          </Button>
        </Stack>
      )}
      {tab === 'shortcuts' && (
        <Stack gap={1}>
          <Typography sx={{ marginBottom: 1 }}>
            <b>Downloads:</b>
          </Typography>
          <Typography>
            1. <b>Shift + tab</b> - go to next field in metadata
          </Typography>
          <Typography>
            2. <b>Tab</b> - go to previous field in metadata
          </Typography>
          <Typography>
            3. <b>Shift + Enter</b> - send files
          </Typography>
          <ManualLink />
        </Stack>
      )}
    </Box>
  );
};

export default ImportRight;
