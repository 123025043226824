import React, { useEffect } from 'react';
import { Button, Stack, Tab, Tabs } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CanvasWidget from '../sections/CanvasWidget/CanvasWidget';
import storage from '../lib/storage';
import { logout } from '../store/userSlice';
import {
  getCurrentProject,
  getTab,
  setCurrentFilter,
  setCurrentProject,
  setTab
} from '../store/globalSlice';
import { ExportLeft, ExportRight } from '../sections/Export';
import { ImportLeft, ImportRight } from '../sections/Import';
import { WorkspaceLeft, WorkspaceRight } from '../sections/Workspace';
import { Tab as TabT } from 'types/global';

const Main: React.FC = () => {
  const tab = useSelector(getTab);
  const currentProject = useSelector(getCurrentProject);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentFilter(''));
    if (currentProject === '') dispatch(setCurrentProject(undefined));
  }, [tab]);

  useEffect(() => {
    const token = storage.get('session');
    if (!token) dispatch(logout());

    const date = Date.parse(storage.get('sessionCreated'));
    if (date) {
      const now = Date.parse(new Date().toString());
      const diff = now - date;
      if (diff > 60 * 60 * 1000) dispatch(logout());
    }
  }, []);

  const changeTab = (tab: TabT) => {
    dispatch(setTab(tab));
  };

  const onLogout = () => {
    dispatch(logout());
    storage.remove('session');
    storage.remove('sessionCreated');
  };

  return (
    <Stack direction='row'>
      <Stack style={{ width: '370px' }}>
        <Stack sx={{ height: '100vh' }}>
          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{ borderBottom: '2px solid #C4C4C4' }}
          >
            <Tabs value={tab} onChange={(e, value: TabT) => changeTab(value)}>
              <Tab value={TabT.Import} label='Downloads' />
              <Tab value={TabT.Main} label='Workspace' />
              <Tab value={TabT.Export} label='Unload' />
            </Tabs>
            <Button onClick={onLogout} sx={{ fontSize: '12px' }}>
              Logout
            </Button>
          </Stack>

          {tab === TabT.Import && <ImportLeft />}
          {tab === TabT.Main && <WorkspaceLeft />}
          {tab === TabT.Export && <ExportLeft />}
        </Stack>
      </Stack>
      <CanvasWidget />
      <Stack sx={{ width: '400px' }}>
        {tab === TabT.Import && <ImportRight />}
        {tab === TabT.Main && <WorkspaceRight />}
        {tab === TabT.Export && <ExportRight />}
      </Stack>
    </Stack>
  );
};
export default Main;
