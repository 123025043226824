import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { Box, CircularProgress, Divider, Stack } from '@mui/material';
import { useEffect } from 'react';
import { defaultApi } from '../../api/default';
import Projects from '../../ui/Projects';
import UploadedFiles from '../../ui/UploadedFiles';
import SavedFiles from '../../ui/SavedFiles';
import {
  getCurrentProject,
  getLoading,
  getProjects,
  getSavedData,
  getUploadedData,
  setLoading,
  setProjectData,
  setProjects
} from '../../store/globalSlice';
import BreadCrumbs from '../../ui/BreadCrumbs';
import Filter from '../../ui/Filter';

const WorkspaceLeft = () => {
  const dispatch = useDispatch<AppDispatch>();

  const projects = useSelector(getProjects);
  const currentProjectName = useSelector(getCurrentProject);
  const uploaded = useSelector(getUploadedData);
  const saved = useSelector(getSavedData);
  const loading = useSelector(getLoading);

  useEffect(() => {
    if (Object.keys(projects).length === 0) onFetch();
  }, []);

  useEffect(() => {
    if (currentProjectName && (!uploaded || !saved)) {
      onFetchCurrentProjectData();
    }
  }, [currentProjectName]);

  const onFetchCurrentProjectData = async () => {
    if (!currentProjectName) return;

    try {
      dispatch(setLoading(true));
      const data = await defaultApi.getAll(currentProjectName);
      dispatch(setProjectData({ project: currentProjectName, data }));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onFetch = async () => {
    try {
      dispatch(setLoading(true));
      const projects = await defaultApi.getProjects();
      dispatch(setProjects(projects));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack m={4} gap={4}>
        <BreadCrumbs />
        <Filter />
      </Stack>

      <Divider />
      {loading && (
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            zIndex: 1000,
            top: 0,
            left: 0
          }}
        >
          <Box
            sx={{
              padding: 2,
              borderRadius: 4,
              backgroundColor: 'white',
              display: 'flex'
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      )}

      {!currentProjectName && <Projects />}

      {uploaded && <UploadedFiles />}
      {saved && uploaded && <Divider />}
      {saved && <SavedFiles />}
    </Stack>
  );
};

export default WorkspaceLeft;
