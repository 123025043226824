import { FormControlLabel } from "@mui/material";
import React, { ChangeEvent } from "react";
import { Checkbox as MuiCheckbox } from "@mui/material";

type CheckboxProps = {
  label: string;
  name: string;
  value: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => unknown;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, name, value, onChange }) => {
  return (
    <FormControlLabel control={
      <MuiCheckbox
        defaultChecked
        checkedIcon={
          <img src="/assets/checkbox.svg" style={{ width: '14px', height: '14px', marginLeft: '4px' }} />
        }
        icon={
          <img src="/assets/blank_checkbox.svg" style={{ width: '14px', height: '14px', marginLeft: '4px' }} />
        }
        value={value}
        onChange={onChange}
        name={name}
      />
    } label={label} />
  );
};

export default Checkbox;
