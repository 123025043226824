export const ENDPOINTS = {
  AUTH: {
    LOGIN: 'auth/login'
  },
  IMPORT: {
    UPLOAD: 'import/upload'
  },
  EXPORT: 'export/export',
  DEFAULT: {
    GET: 'image/get?by_link=true',
    GET_TRANSFORMED: 'image/getTransformed',
    GET_ALL: 'image/getAll',
    PROJECTS: 'image/projects',
    UPDATE: 'image/updateInwork',
    DELETE: 'image'
  },
  PRESETS: {
    CREATE: 'preset/create',
    UPDATE: 'preset/update',
    GET: 'preset/get',
    GET_ALL: 'preset/getAll',
    DELETE: 'preset'
  }
} as const;