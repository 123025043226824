import { Box, Stack, Typography } from '@mui/material';
import { Preset } from 'types/global';
import React from 'react';
import NoteIcon from '@mui/icons-material/Note';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentPreset,
  getPresets,
  setCurrentPreset
} from 'store/presetSlice';
import { AppDispatch } from 'store';
import { setBleachBound } from 'store/measurementSlice';
import ClearIcon from '@mui/icons-material/Clear';
import { fetchPresets, resetPreset } from 'store/thunks';
import { presetApi } from 'api/presets';
import { toast } from 'react-toastify';
import CancelIcon from '@mui/icons-material/Cancel';

const Presets: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const currentPreset = useSelector(getCurrentPreset);
  const presets = useSelector(getPresets);

  const onResetPreset = () => {
    dispatch(resetPreset());
  };

  const onPresetClick = (preset: Preset) => {
    dispatch(setCurrentPreset(preset));
    setBounds(preset);
  };

  const setBounds = (preset: Preset) => {
    const { brightness } = preset;
    dispatch(
      setBleachBound({ bleachValue: 6, value: brightness[0], type: 'max' })
    );
    dispatch(
      setBleachBound({ bleachValue: 5, value: brightness[0], type: 'min' })
    );
    dispatch(
      setBleachBound({ bleachValue: 5, value: brightness[1], type: 'max' })
    );
    dispatch(
      setBleachBound({ bleachValue: 4, value: brightness[1], type: 'min' })
    );
    dispatch(
      setBleachBound({ bleachValue: 4, value: brightness[2], type: 'max' })
    );
    dispatch(
      setBleachBound({ bleachValue: 3, value: brightness[2], type: 'min' })
    );
    dispatch(
      setBleachBound({ bleachValue: 3, value: brightness[3], type: 'max' })
    );
    dispatch(
      setBleachBound({ bleachValue: 2, value: brightness[3], type: 'min' })
    );
    dispatch(
      setBleachBound({ bleachValue: 2, value: brightness[4], type: 'max' })
    );
    dispatch(
      setBleachBound({ bleachValue: 1, value: brightness[4], type: 'min' })
    );
  };

  const onDeletePreset = async (id: string) => {
    try {
      await presetApi.delete(id);
      toast.success('Preset deleted successfully');
      dispatch(resetPreset());
      await dispatch(fetchPresets());
    } catch (error) {
      console.log(error);
      toast.error('Error deleting preset');
    }
  };

  return (
    <Stack>
      <Stack gap={2} sx={{ maxHeight: '200px', overflowY: 'scroll' }}>
        <Stack
          direction='row'
          alignItems='center'
          gap={4}
          px={3}
          py={2}
          onClick={onResetPreset}
          sx={{
            backgroundColor: currentPreset === null ? '#0000FF1A' : '#f1f1f1',
            borderRadius: 2,
            cursor: currentPreset === null ? 'default' : 'pointer',
            transition: '0.2s',
            '&:hover': {
              backgroundColor:
                currentPreset === null ? '#0000FF1A' : 'rgb(246,246,246)'
            }
          }}
        >
          <Box
            sx={{
              backgroundColor: 'rgb(255,255,255)',
              borderRadius: 2,
              width: 26,
              height: 26,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ClearIcon sx={{ fontSize: 16, color: 'blue' }} />
          </Box>
          <Typography>No preset</Typography>
        </Stack>

        {presets.map((preset) => (
          <Stack
            direction='row'
            alignItems='center'
            gap={4}
            key={preset._id}
            justifyContent={'space-between'}
            px={3}
            py={2}
            onClick={() => onPresetClick(preset)}
            sx={{
              backgroundColor:
                currentPreset !== null && currentPreset._id === preset._id
                  ? '#0000FF1A'
                  : '#f1f1f1',
              borderRadius: 2,
              cursor:
                currentPreset !== null && currentPreset._id === preset._id
                  ? 'default'
                  : 'pointer',
              transition: '0.2s',
              '&:hover': {
                backgroundColor:
                  currentPreset !== null && currentPreset._id === preset._id
                    ? '#0000FF1A'
                    : 'rgb(246,246,246)'
              }
            }}
          >
            <Stack direction='row' alignItems='center' gap={4}>
              <Box
                sx={{
                  backgroundColor: 'rgb(255,255,255)',
                  borderRadius: 2,
                  width: 26,
                  height: 26,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <NoteIcon sx={{ fontSize: 16, color: 'blue' }} />
              </Box>
              <Typography>{`${preset.brightness[0]}, ${preset.brightness[1]}, ${preset.brightness[2]}, ${preset.brightness[3]}, ${preset.brightness[4]}`}</Typography>
            </Stack>
            <CancelIcon
              onClick={() => onDeletePreset(preset._id)}
              sx={{
                cursor: 'pointer',
                color: 'grey',
                transition: '0.2s',
                '&:hover': { color: 'blue' }
              }}
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default Presets;
