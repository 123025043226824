import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store';
import { FileT } from '../types/global';
import {
  getActiveFile,
  getCurrentFilter,
  getSorting,
  getUploadedData
} from '../store/globalSlice';
import { fetchUploadedThunk } from 'store/thunks';

const UploadedFiles: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const sorting = useSelector(getSorting);
  const uploaded = useSelector(getUploadedData);
  const uploadedSorted = uploaded ? [...(uploaded as FileT[])] : [];
  const transformed =
    sorting === 'none'
      ? uploadedSorted
      : sorting === 'asc'
        ? [...uploadedSorted].sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
              numeric: true,
              sensitivity: 'base'
            })
          )
        : [...uploadedSorted].sort((a, b) =>
            b.name.localeCompare(a.name, undefined, {
              numeric: true,
              sensitivity: 'base'
            })
          );
  const activeFile = useSelector(getActiveFile) as FileT;
  const filter = useSelector(getCurrentFilter);

  const fetchUploaded = async (file: FileT) => {
    if (file.id === activeFile?.id) return;

    await dispatch(fetchUploadedThunk(file));
  };

  return (
    <Stack
      gap={1}
      sx={{ height: 'calc(50vh - 150px / 2 - 16px)', paddingBottom: '16px' }}
    >
      <Typography
        sx={{ padding: '12px 16px 4px', textDecoration: 'underline' }}
      >
        Uploaded
      </Typography>
      <Stack sx={{ height: '100%', overflowY: 'scroll' }}>
        {uploaded &&
          transformed.map((file: FileT, idx: number) => {
            if ((filter && file.name.includes(filter)) || !filter)
              return (
                <Stack
                  onClick={() => fetchUploaded(file)}
                  direction='row'
                  key={idx}
                  gap={2}
                  px={3}
                  py={2}
                  alignItems='flex-end'
                  sx={{
                    backgroundColor:
                      activeFile?.id === file.id ? '#0000FF1A' : 'unset',
                    cursor: 'pointer',
                    transition: '0.2s',
                    '&:hover': {
                      backgroundColor:
                        activeFile?.id === file.id
                          ? '0000FF1A'
                          : 'rgba(0,0,255,0.03)'
                    }
                  }}
                >
                  {file.id === activeFile?.id && (
                    <>
                      <img
                        style={{ display: 'none' }}
                        id='active-img'
                        crossOrigin='anonymous'
                        src={activeFile?.file}
                      />
                      {activeFile?.transformedFile && (
                        <img
                          style={{ display: 'none' }}
                          id='active-img__transformed'
                          src={activeFile.transformedFile}
                        />
                      )}
                    </>
                  )}
                  <Typography
                    sx={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      color: '#626262',
                      width: '300px'
                    }}
                  >
                    {file.name}
                  </Typography>
                </Stack>
              );
          })}
      </Stack>
    </Stack>
  );
};

export default UploadedFiles;
