import * as React from 'react';
import Button from '@mui/material/Button';
import {
  Stack,
  TextField as MuiTextField,
  TextFieldProps,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { setBleachBound } from '../store/measurementSlice';
import { getCurrentPreset } from 'store/presetSlice';
import { presetApi } from 'api/presets';
import { toast } from 'react-toastify';
import { fetchPresets } from 'store/thunks';

export default function Settings() {
  const currentPreset = useSelector(getCurrentPreset);

  const bounds = useSelector(
    (state: RootState) => state.measurement.bleachBounds
  );
  const dispatch = useDispatch<AppDispatch>();

  const setBound = (
    value: number,
    bleachValue: 1 | 2 | 3 | 4 | 5 | 6,
    type: 'min' | 'max'
  ) => {
    dispatch(setBleachBound({ bleachValue, value, type }));
  };

  const checkBounds = () => {
    if (
      bounds[6].max > 254 ||
      bounds[5].max > 254 ||
      bounds[4].max > 254 ||
      bounds[3].max > 254 ||
      bounds[2].max > 254
    ) {
      toast.error('Each value must not be greater than 254');
      return 1;
    }

    if (
      bounds[6].max > bounds[5].max ||
      bounds[5].max > bounds[4].max ||
      bounds[4].max > bounds[3].max ||
      bounds[3].max > bounds[2].max
    ) {
      toast.error('Each value must be greater than other');
      return 1;
    }

    return 0;
  };

  const onSavePreset = async () => {
    const exitCode = checkBounds();
    if (exitCode === 1) return;

    try {
      await presetApi.create([
        bounds[6].max,
        bounds[5].max,
        bounds[4].max,
        bounds[3].max,
        bounds[2].max
      ]);
      toast.success('Preset created successfully');
      await dispatch(fetchPresets());
    } catch (error) {
      console.log(error);
      toast.error('Error creating preset');
    }
  };

  const onUpdatePreset = async () => {
    if (!currentPreset) return;

    try {
      await presetApi.update(currentPreset._id, [
        bounds[6].max,
        bounds[5].max,
        bounds[4].max,
        bounds[3].max,
        bounds[2].max
      ]);
      toast.success('Preset updated successfully');
      await dispatch(fetchPresets());
    } catch (error) {
      console.log(error);
      toast.error('Error updating preset');
    }
  };



  return (
    <div>
      <Stack gap={1} sx={{ paddingInline: 4}}>
        <Stack direction='row' gap={4} alignItems='center'>
          <Typography>6:</Typography>
          <Stack direction='row' gap={2} alignItems='center'>
            <Typography>{'value'}</Typography>
            <Typography>{'<'}</Typography>
            <TextField
              value={bounds[6].max}
              onChange={(e) => setBound(Number(e.target.value), 6, 'max')}
            />
          </Stack>
        </Stack>
        <Stack direction='row' gap={4} alignItems='center'>
          <Typography>5:</Typography>
          <Stack direction='row' gap={2} alignItems='center'>
            <TextField
              value={bounds[6].max}
              disabled
              onChange={(e) => setBound(Number(e.target.value), 5, 'min')}
            />
            <Typography>{'<'}</Typography>
            <Typography>{'value'}</Typography>
            <Typography>{'<'}</Typography>
            <TextField
              value={bounds[5].max}
              onChange={(e) => setBound(Number(e.target.value), 5, 'max')}
            />
          </Stack>
        </Stack>
        <Stack direction='row' gap={4} alignItems='center'>
          <Typography>4:</Typography>
          <Stack direction='row' gap={2} alignItems='center'>
            <TextField
              value={bounds[5].max}
              disabled
              onChange={(e) => setBound(Number(e.target.value), 4, 'min')}
            />
            <Typography>{'<'}</Typography>
            <Typography>{'value'}</Typography>
            <Typography>{'<'}</Typography>
            <TextField
              value={bounds[4].max}
              onChange={(e) => setBound(Number(e.target.value), 4, 'max')}
            />
          </Stack>
        </Stack>
        <Stack direction='row' gap={4} alignItems='center'>
          <Typography>3:</Typography>
          <Stack direction='row' gap={2} alignItems='center'>
            <TextField
              value={bounds[4].max}
              disabled
              onChange={(e) => setBound(Number(e.target.value), 3, 'min')}
            />
            <Typography>{'<'}</Typography>
            <Typography>{'value'}</Typography>
            <Typography>{'<'}</Typography>
            <TextField
              value={bounds[3].max}
              onChange={(e) => setBound(Number(e.target.value), 3, 'max')}
            />
          </Stack>
        </Stack>
        <Stack direction='row' gap={4} alignItems='center'>
          <Typography>2:</Typography>
          <Stack direction='row' gap={2} alignItems='center'>
            <TextField
              value={bounds[3].max}
              disabled
              onChange={(e) => setBound(Number(e.target.value), 2, 'min')}
            />
            <Typography>{'<'}</Typography>
            <Typography>{'value'}</Typography>
            <Typography>{'<'}</Typography>
            <TextField
              value={bounds[2].max}
              onChange={(e) => setBound(Number(e.target.value), 2, 'max')}
            />
          </Stack>
        </Stack>
        <Stack direction='row' gap={4} alignItems='center'>
          <Typography>1:</Typography>
          <Stack direction='row' gap={2} alignItems='center'>
            <TextField
              value={bounds[2].max}
              disabled
              onChange={(e) => setBound(Number(e.target.value), 1, 'min')}
            />
            <Typography>{'<'}</Typography>
            <Typography>{'value'}</Typography>
          </Stack>
        </Stack>

        {currentPreset === null ? (
          <Button onClick={onSavePreset} variant='contained' fullWidth>
            Save
          </Button>
        ) : (
          <Stack gap={2}>
            <Button onClick={onUpdatePreset} variant='contained'>
              Update
            </Button>
          </Stack>
        )}
      </Stack>
    </div>
  );
}

const TextField: React.FC<TextFieldProps> = (props) => {
  return (
    <MuiTextField
      type='number'
      sx={{
        width: 70,
        '& .MuiInputBase-root': {
          paddingY: '4px !important'
        }
      }}
      {...props}
    />
  );
};
