import { drawPoint } from "./point";

export const drawMask = ({ ctx, mask }: {
  ctx: CanvasRenderingContext2D,
  mask: Set<string>
}) => {
  for (const point of [...mask]) {
    const x = Math.round(Number(point.split(',')[0]));
    const y = Math.round(Number(point.split(',')[1]));
    drawPoint({ ctx, x: x, y: y, size: 1, fillColor: 'rgba(255,17,17,0.1)', strokeColor: 'transparent' });
  }
};

export const translateMask = ({ ctx, dx, dy, mask, setMask }: {
  ctx: CanvasRenderingContext2D,
  dx: number,
  dy: number,
  mask: Set<string>,
  setMask: (mask: Set<string>) => void,
}) => {
  const newMask: Set<string> = new Set();
  for (const point of [...mask]) {
    const newX = Math.round(Number(point.split(',')[0]) + dx);
    const newY = Math.round(Number(point.split(',')[1]) + dy);
    drawPoint({ ctx, x: newX, y: newY, size: 1, fillColor: 'rgba(255,17,17,0.1)', strokeColor: 'transparent' });
    newMask.add(`${newX},${newY}`);
  }
  setMask(newMask);
};
