import { createSlice } from "@reduxjs/toolkit";

type UserState = {
  isAuth: boolean
};

const initialState: UserState = {
  isAuth: false
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state) {
      state.isAuth = true;
    },
    logout(state) {
      state.isAuth = false;
    },
  },
  selectors: {
    isUserAuth: (state) => state.isAuth
  }
});

export const { login, logout } = userSlice.actions;
export const { isUserAuth } = userSlice.selectors;
export default userSlice.reducer;
