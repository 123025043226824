import React, { useMemo } from "react";
import { Stack, TextField, Typography } from "@mui/material";
import ActionButton from "./ActionButton";
import { FileT, MeasureMode } from "../types/global";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { getMeasurementMultiplier, getMeasurements, setMultiplier } from "../store/measurementSlice";
import { getActiveFile, getMeasureMode, setMeasureMode } from "../store/globalSlice";

type MeasurementRowProps = {
  idx: 1 | 2 | 3 | 4;
  subtitle: string;
}

const MeasurementRow: React.FC<MeasurementRowProps> = ({ idx, subtitle }) => {
  const dispatch = useDispatch<AppDispatch>();

  const activeFile = useSelector(getActiveFile) as FileT;
  const measureMode = useSelector(getMeasureMode);
  const measurements = useSelector(getMeasurements);
  const measurementMultiplier = useSelector(getMeasurementMultiplier);

  const type = useMemo(() => {
    switch (idx) {
      case 1:
        return MeasureMode.Measure1;
      case 2:
        return MeasureMode.Measure2;
      case 3:
        return MeasureMode.Measure3;
      case 4:
        return MeasureMode.Measure4;
    }
  }, [idx]);

  const chooseRow = (idx: 1 | 2 | 3 | 4) => {
    let type: MeasureMode;
    switch (idx) {
      case 1:
        if (measureMode !== MeasureMode.Measure1) type = MeasureMode.Measure1;
        else type = MeasureMode.Unset;
        break;
      case 2:
        if (measureMode !== MeasureMode.Measure2) type = MeasureMode.Measure2;
        else type = MeasureMode.Unset;
        break;
      case 3:
        if (measureMode !== MeasureMode.Measure3) type = MeasureMode.Measure3;
        else type = MeasureMode.Unset;
        break;
      case 4:
        if (measureMode !== MeasureMode.Measure4) type = MeasureMode.Measure4;
        else type = MeasureMode.Unset;
        break;
    }
    dispatch(setMeasureMode(type));
  };

  const handleChangeMultiplier = (multiplier: number) => {
    dispatch(setMultiplier(multiplier));
  };

  return (
    <Stack
      direction='row'
      gap={2}
      alignItems='center'
      sx={{
        backgroundColor: measureMode === type ? '#0000FF1A' : 'unset',
        padding: '6px 10px',
      }}
    >
      <Typography sx={{ width: 100 }}>Measurement {idx} ({subtitle}):</Typography>
      <TextField
        size='small'
        sx={{
          width: 70,
          borderColor: 'transparent',
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: '4px'
        }}
        value={measurements[idx] ?? ''}
        disabled
      />
      <ActionButton
        onClick={() => chooseRow(idx)}
        image='assets/ruler.svg'
        disabled={Boolean(activeFile?.transformedFile) || !activeFile}
        active={measureMode === type}
      />
      {idx === 1 &&
        <TextField
          size='small'
          sx={{ width: 70 }}
          value={measurementMultiplier}
          type='number'
          onChange={(e) => handleChangeMultiplier(Number(e.target.value))}
          disabled={Boolean(activeFile?.transformedFile) || !activeFile}
        />
      }
    </Stack>
  );
};

export default MeasurementRow;
