import React from "react";
import { Button } from "@mui/material";
import { defaultApi } from "../api/default";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { FileT } from "../types/global";
import { getActiveFile, resetTransformed, setLoading, setTransformed } from "../store/globalSlice";

const TransformImage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const activeFile = useSelector(getActiveFile) as FileT;

  const fetchTransformed = async () => {
    if (!activeFile) return;

    try {
      dispatch(setLoading(true));

      const response: any = await defaultApi.getTransformed(activeFile.id);
      dispatch(setTransformed(`data:image;base64,${response.image}`));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const hideTransformed = () => {
    dispatch(resetTransformed());
  };

  return (
    <>
      {activeFile?.transformedFile ?
        <Button variant='contained' sx={{ margin: '8px' }} onClick={hideTransformed} disabled={!activeFile}>
          Hide transformed image
        </Button> :
        <Button variant='contained' sx={{ margin: '8px' }} onClick={fetchTransformed} disabled={!activeFile}>
          Get transformed image
        </Button>
      }
    </>
  );
};

export default TransformImage;
