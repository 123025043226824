import React from "react";
import { Stack } from "@mui/material";

type ActionButtonProps = {
  onClick?: () => unknown;
  image: string;
  disabled?: boolean;
  active?: boolean;
  id?: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({onClick, image, disabled, active, id}) => {
  return (
    <Stack
      onClick={!disabled ? onClick : undefined}
      alignItems='center'
      justifyContent='center'
      id={id}
      sx={!disabled ? {
        borderRadius: '6px',
        cursor: 'pointer',
        border: '2px solid #0000FF',
        width: '24px',
        height: '24px',
        backgroundColor: active ? '#0000FF1A' : '#ffffff',
        transition: '0.2s',
        '&:hover': { backgroundColor: active ? '#0000FF1A' : '#e7e7e7' }
      } : {
        borderRadius: '6px',
        cursor: 'default',
        border: '2px solid #d3d3d3',
        width: '24px',
        height: '24px',
        backgroundColor: '#d3d3d3',
      }}
    >
      <img src={image} style={{ width: 16, opacity: !disabled ? 1 : 0.3 }} />
    </Stack>
  );
};

export default ActionButton;
