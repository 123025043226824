import { Button, FormGroup, Stack, Typography } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { exportApi } from '../../api/export';
import { toast } from 'react-toastify';
import {
  getExportChecks,
  getExportIds,
  resetExport,
  setExportCheck
} from '../../store/exportSlice';
import Checkbox from '../../ui/Checkbox';
import ManualLink from 'ui/ManualLink';

const ExportRight = () => {
  const dispatch = useDispatch<AppDispatch>();

  const checks = useSelector(getExportChecks);
  const ids = useSelector(getExportIds);
  const [tab, setTab] = useState<'unload' | 'shortcuts'>('unload');

  const onSubmit = async () => {
    try {
      const body = {
        ids,
        meta: checks.metadata,
        coms: checks.comments,
        blch: checks.bleachScores,
        meas: checks.measurements
      };
      const res = await exportApi.export(body);
      const url = (window as any).URL.createObjectURL(new Blob([res]));
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.setAttribute('download', `data.xls`);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      (window as any).URL.revokeObjectURL(url);

      toast.success('Exported successfully');
      dispatch(resetExport());
    } catch (err) {
      console.log(err);
    }
  };

  const onSetCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as
      | 'metadata'
      | 'bleachScores'
      | 'measurements'
      | 'comments';

    dispatch(setExportCheck({ name: name, value: e.target.checked }));
  };

  return (
    <Stack sx={{ padding: '16px 10px' }}>
      <Stack direction='row' alignItems='center' sx={{ marginBottom: '8px' }}>
        <Typography
          sx={{
            cursor: tab === 'unload' ? 'default' : 'pointer',
            color: tab === 'unload' ? 'black' : 'grey',
            paddingRight: '10px',
            textDecoration: tab === 'unload' ? 'underline' : 'none'
          }}
          onClick={() => setTab('unload')}
        >
          Unload
        </Typography>
        <Typography>|</Typography>
        <Typography
          sx={{
            cursor: tab === 'shortcuts' ? 'default' : 'pointer',
            color: tab === 'shortcuts' ? 'black' : 'grey',
            paddingInline: '10px',
            textDecoration: tab === 'shortcuts' ? 'underline' : 'none'
          }}
          onClick={() => setTab('shortcuts')}
        >
          Shortcuts
        </Typography>
      </Stack>

      {tab === 'unload' && (
        <Stack>
          <Stack>
            <FormGroup>
              <Checkbox
                label='Metadata'
                name='metadata'
                value={checks.metadata}
                onChange={onSetCheck}
              />
              <Checkbox
                label='Bleach scores'
                name='bleachScores'
                value={checks.bleachScores}
                onChange={onSetCheck}
              />
              <Checkbox
                label='Measurements'
                name='measurements'
                value={checks.measurements}
                onChange={onSetCheck}
              />
              <Checkbox
                label='Comments'
                name='comments'
                value={checks.measurements}
                onChange={onSetCheck}
              />
            </FormGroup>
          </Stack>
          <Button
            onClick={onSubmit}
            variant='contained'
            sx={{ marginTop: '18px' }}
          >
            Send
          </Button>
        </Stack>
      )}
      {tab === 'shortcuts' && (
        <Stack gap={1}>
          <Typography sx={{ marginBottom: 1 }}>
            <b>Unload:</b>
          </Typography>
          <Typography>
            1. <b>Shift + click on file</b> - select file from ... to ...
          </Typography>
          <ManualLink />
        </Stack>
      )}
    </Stack>
  );
};

export default ExportRight;
