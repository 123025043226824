import { FileT, MeasureMode, Tab } from '../types/global';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type GlobalState = {
    measureMode: MeasureMode;
    activeFile?: FileT;
    loading: boolean;
    currentProject?: string;
    currentFilter?: string;
    projects: Record<
        string,
        {
            new_data?: { id: string; name: string }[];
            inwork_data?: { id: string; name: string }[];
        }
    >;
    sorting: 'asc' | 'desc' | 'none';
    tab: Tab;
};

const initialState: GlobalState = {
    measureMode: MeasureMode.Unset,
    loading: false,
    projects: {},
    sorting: 'none',
    tab: Tab.Main
};

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setMeasureMode(state, action: PayloadAction<MeasureMode>) {
            state.measureMode = action.payload;
        },
        setActiveFile(state, action: PayloadAction<FileT | undefined>) {
            state.activeFile = action.payload;
        },
        updateActiveFile(state, action: PayloadAction<Partial<FileT>>) {
            if (state.activeFile)
                state.activeFile = {
                    ...state.activeFile,
                    ...action.payload,
                };
        },
        setTransformed(state, action: PayloadAction<string>) {
            if (state.activeFile) {
                state.measureMode = MeasureMode.Unset;
                state.activeFile.transformedFile = action.payload;
            }
        },
        resetTransformed(state) {
            if (state.activeFile) state.activeFile.transformedFile = undefined;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setCurrentProject(state, action: PayloadAction<string | undefined>) {
            state.currentFilter = '';
            state.currentProject = action.payload;
        },
        setCurrentFilter(state, action: PayloadAction<string | undefined>) {
            state.currentFilter = action.payload;
        },
        setProjects(state, action: PayloadAction<string[]>) {
            const projects = {};
            for (const project of action.payload) {
                projects[project] = {};
            }

            state.projects = projects;
        },
        setProjectData(
            state,
            action: PayloadAction<{
                project: string;
                data: {
                    new_data?: { id: string; name: string }[];
                    inwork_data?: { id: string; name: string }[];
                };
            }>
        ) {
            const { project, data } = action.payload;
            state.projects[project] = data;
        },
        addProjectData(
            state,
            action: PayloadAction<{
                project: string;
                type: 'new_data' | 'inwork_data';
                data: { id: string; name: string }[];
            }>
        ) {
            const { data, type, project } = action.payload;

            let newData: { id: string; name: string }[] = [];
            if (!state.projects[project][type]) newData = [...data];
            else newData = [...state.projects[project][type], ...data];

            state.projects[project][type] = newData;
        },
        removeProjectData(
            state,
            action: PayloadAction<{
                project: string;
                type: 'new_data' | 'inwork_data';
                ids: string[];
            }>
        ) {
            const { ids, type, project } = action.payload;
            const oldData = state.projects[project][type];
            if (!oldData) return state;

            const newData: { id: string; name: string }[] = [];
            for (const item of oldData) {
                if (!ids.includes(item.id)) newData.push(item);
            }

            state.projects[project][type] = newData;
        },
        setSorting(state, action: PayloadAction<'asc' | 'desc' | 'none'>) {
            state.sorting = action.payload;
        },
        setTab(state, action: PayloadAction<Tab>) {
            state.tab = action.payload;
        }
    },
    selectors: {
        getMeasureMode: (state) => state.measureMode,
        getActiveFile: (state) => state.activeFile,
        getCurrentProject: (state) => state.currentProject,
        getCurrentProjectObject: (state) =>
            state.currentProject
                ? state.projects[state.currentProject]
                : undefined,
        getProjects: (state) => state.projects,
        getCurrentFilter: (state) => state.currentFilter,
        getLoading: (state) => state.loading,
        getSorting: (state) => state.sorting,
        getSavedData: (state) => {
            const currentProjectObject = state.currentProject
                ? state.projects[state.currentProject]
                : undefined;
            if (!currentProjectObject) return;

            return currentProjectObject['inwork_data'];
        },
        getUploadedData: (state) => {
            const currentProjectObject = state.currentProject
                ? state.projects[state.currentProject]
                : undefined;
            if (!currentProjectObject) return;

            return currentProjectObject['new_data'];
        },
        getTab: (state) => state.tab
    },
});

export const {
    setMeasureMode,
    addProjectData,
    removeProjectData,
    setProjectData,
    setProjects,
    setCurrentProject,
    setCurrentFilter,
    setLoading,
    setTransformed,
    resetTransformed,
    updateActiveFile,
    setActiveFile,
    setSorting,
    setTab
} = globalSlice.actions;
export const {
    getMeasureMode,
    getCurrentProject,
    getSavedData,
    getUploadedData,
    getProjects,
    getCurrentFilter,
    getActiveFile,
    getLoading,
    getSorting,
    getTab
} = globalSlice.selectors;
export default globalSlice.reducer;
