import { PointT } from "../../types/global";

export const drawPoint = ({ ctx, x, y, size = 4, fillColor = 'red', strokeColor = 'red' }: {
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  size?: number,
  fillColor?: string,
  strokeColor?: string
}) => {
  ctx.beginPath();
  ctx.strokeStyle = strokeColor;
  ctx.fillStyle = fillColor;
  ctx.lineWidth = 2;
  ctx.strokeRect(x - size, y - size, size * 2, size * 2);
  ctx.fillRect(x - size, y - size, size * 2, size * 2);
  ctx.stroke();
};

export const drawPoint_rel = ({ ctx, x, y, size, fillColor, strokeColor, zoom = 1 }: {
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  size?: number,
  fillColor?: string,
  strokeColor?: string,
  zoom?: number
}) => {
  const relX = x / zoom;
  const relY = y / zoom;
  drawPoint({ ctx, x: relX, y: relY, size, fillColor, strokeColor });
};

export const drawAllPoints = ({ ctx, points }: {
  ctx: CanvasRenderingContext2D,
  points: PointT[]
}) => {
  for (const point of points) {
    drawPoint({ ctx, x: point.x, y: point.y, size: point.size });
  }
};

export const drawAllPoints_rel = ({ ctx, points, zoom = 1 }: {
  ctx: CanvasRenderingContext2D,
  points: PointT[],
  zoom?: number
}) => {
  for (const point of points) {
    drawPoint_rel({ ctx, x: point.x, y: point.y, size: point.size, zoom });
  }
};

export const translateAllPoints = ({ ctx, dx, dy, points, setPoints }: {
  ctx: CanvasRenderingContext2D,
  dx: number,
  dy: number,
  points: PointT[],
  setPoints: (points: PointT[]) => void
}) => {
  const newPoints: PointT[] = [];
  for (const point of points) {
    const newX = point.x + dx;
    const newY = point.y + dy;
    drawPoint({ ctx, x: newX, y: newY, size: point.size });
    newPoints.push({ x: newX, y: newY, size: point.size });
  }
  setPoints(newPoints);
};

export const translateAllPoints_rel = ({ ctx, dx, dy, points, zoom = 1, setPoints }: {
  ctx: CanvasRenderingContext2D,
  dx: number,
  dy: number,
  points: PointT[],
  zoom?: number,
  setPoints: (points: PointT[]) => void
}) => {
  const newPoints: PointT[] = [];
  for (const point of points) {
    const newX = point.x + dx;
    const newY = point.y + dy;
    drawPoint_rel({ ctx, x: newX, y: newY, size: point.size, zoom });
    newPoints.push({ x: newX, y: newY, size: point.size });
  }
  setPoints(newPoints);
};
