import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Bound, ColorT } from "../types/global";

const defaultBounds = {
  6: { min: 0, max: 40 },
  5: { min: 40, max: 55 },
  4: { min: 55, max: 64 },
  3: { min: 64, max: 80 },
  2: { min: 80, max: 87 },
  1: { min: 87, max: 254 },
};

type MeasurementState = {
  colors: {
    1?: ColorT;
    2?: ColorT;
    3?: ColorT;
  },
  bleaches: {
    1?: number;
    2?: number;
    3?: number;
  },
  totalBleach?: number;
  measurements: {
    1: number;
    2?: number;
    3?: number;
    4?: number;
  },
  measurementMultiplier: number,
  pickSizes: {
    1: number;
    2: number;
    3: number;
  },
  maskSize: number,
  maskEraserSize: number,
  maskColor?: ColorT;
  bleachBounds: {
    6: Bound;
    5: Bound;
    4: Bound;
    3: Bound;
    2: Bound;
    1: Bound;
  }
};

const initialState: MeasurementState = {
  colors: {},
  pickSizes: { 1: 10, 2: 10, 3: 10 },
  bleaches: {},
  totalBleach: undefined,
  measurements: { 1: 1 },
  measurementMultiplier: 1,
  maskSize: 10,
  maskEraserSize: 10,
  bleachBounds: defaultBounds
};

const measurementSlice = createSlice({
  name: 'measurement',
  initialState,
  reducers: {
    setBleach(state, action: PayloadAction<{ idx: 1 | 2 | 3; value: number }>) {
      const { idx, value } = action.payload;
      state.bleaches[idx] = value;

      if (idx == 1 && state.bleaches?.[2] && state.bleaches?.[3]) {
        state.totalBleach = (value + state.bleaches[2] + state.bleaches[3]) / 3;
      }

      if (idx == 2 && state.bleaches?.[1] && state.bleaches?.[3]) {
        state.totalBleach = (value + state.bleaches[1] + state.bleaches[3]) / 3;
      }

      if (idx == 3 && state.bleaches?.[1] && state.bleaches?.[2]) {
        state.totalBleach = (value + state.bleaches[1] + state.bleaches[2]) / 3;
      }
    },
    setTotalBleach(state, action: PayloadAction<number | undefined>) {
      state.totalBleach = action.payload;
    },
    setMaskColor(state, action: PayloadAction<ColorT | undefined>) {
      state.maskColor = action.payload;
    },
    setBleaches(state, action: PayloadAction<{ 1?: number; 2?: number; 3?: number }>) {
      const bleaches = action.payload;
      state.bleaches = bleaches;
      if (bleaches[1] && bleaches[2] && bleaches[3])
        state.totalBleach = (bleaches[1] + bleaches[2] + bleaches[3]) / 3;
    },
    setColor(state, action: PayloadAction<{ idx: 1 | 2 | 3; value: ColorT }>) {
      const { idx, value } = action.payload;
      state.colors[idx] = value;
    },
    setColors(state, action: PayloadAction<{ 1?: ColorT; 2?: ColorT; 3?: ColorT }>) {
      state.colors = action.payload;
    },
    setPickSize(state, action: PayloadAction<{ idx: 1 | 2 | 3; value: number }>) {
      const { idx, value } = action.payload;
      state.pickSizes[idx] = value;
    },
    setPickSizes(state, action: PayloadAction<{ 1: number; 2: number; 3: number }>) {
      state.pickSizes = action.payload;
    },
    setMaskSize(state, action: PayloadAction<number>) {
      state.maskSize = action.payload;
    },  
    setMaskEraserSize(state, action: PayloadAction<number>) {
      state.maskEraserSize = action.payload;
    },
    setMeasurement(state, action: PayloadAction<{ idx: 1 | 2 | 3 | 4; value: number }>) {
      const { idx, value } = action.payload;
      state.measurements[idx] = value;
    },
    setMeasurements(state, action: PayloadAction<{ 1: number; 2?: number; 3?: number; 4?: number }>) {
      state.measurements = action.payload;
    },
    setMultiplier(state, action: PayloadAction<number>) {
      state.measurementMultiplier = action.payload;
    },
    setBleachBound(state, action: PayloadAction<{
      type: 'min' | 'max',
      value: number,
      bleachValue: 1 | 2 | 3 | 4 | 5 | 6
    }>) {
      const { bleachValue, type, value } = action.payload;
      state.bleachBounds[bleachValue][type] = value;
    },
    resetBounds(state) {
      state.bleachBounds = defaultBounds;
    },
    resetMeasurements(state) {
      return {
        ...initialState,
        pickSizes: state.pickSizes,
        maskSize: state.maskSize,
        bleachBounds: state.bleachBounds
      };
    }
  },
  selectors: {
    getColors: (state) => state.colors,
    getMaskColor: (state) => state.maskColor,
    getPickSizes: (state) => state.pickSizes,
    getMaskSize: (state) => state.maskSize,
    getMaskEraserSize: (state) => state.maskEraserSize,
    getBleaches: (state) => state.bleaches,
    getTotalBleach: (state) => state.totalBleach,
    getBleachBounds: (state) => state.bleachBounds,
    getMeasurements: (state) => state.measurements,
    getMeasurementMultiplier: (state) => state.measurementMultiplier,
  }
});

export const {
  setColor,
  setMeasurement,
  setBleach,
  setPickSize,
  setMultiplier,
  setBleachBound,
  resetBounds,
  resetMeasurements,
  setMeasurements,
  setColors,
  setBleaches,
  setPickSizes,
  setMaskSize,
  setMaskEraserSize,
  setTotalBleach,
  setMaskColor
} = measurementSlice.actions;
export const {
  getMeasurements,
  getMeasurementMultiplier,
  getBleaches,
  getTotalBleach,
  getBleachBounds,
  getColors,
  getPickSizes,
  getMaskSize,
  getMaskEraserSize,
  getMaskColor
} = measurementSlice.selectors;
export default measurementSlice.reducer;
