import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { getCurrentFilter, getProjects, setCurrentProject } from "../store/globalSlice";

const Projects: React.FC<{ canAddNew?: boolean }> = ({ canAddNew }) => {
  const dispatch = useDispatch<AppDispatch>();

  const projects = useSelector(getProjects);
  const filter = useSelector(getCurrentFilter);

  const onProjectClick = (project: string) => {
    dispatch(setCurrentProject(project));
  };

  return (
    <Stack gap={2} mt={4}>
      {projects && Object.keys(projects).map((projectName, idx) => {
        if (filter && projectName.includes(filter) || !filter)
          return (
            <Stack
              direction='row'
              alignItems='center'
              gap={4} key={idx}
              px={3}
              mx={3}
              py={2}
              sx={{
                backgroundColor: '#f1f1f1',
                borderRadius: 2,
                cursor: 'pointer',
                transition: '0.2s',
                '&:hover': { backgroundColor: 'rgb(246,246,246)' }
              }}
              onClick={() => onProjectClick(projectName)}
            >
              <Box sx={{
                backgroundColor: 'rgb(255,255,255)',
                borderRadius: 2,
                width: 26,
                height: 26,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <img src='/assets/folder.svg' style={{
                  width: 16,
                  filter: 'invert(15%) sepia(100%) saturate(7470%) hue-rotate(233deg) brightness(99%) contrast(109%)'
                }} />
              </Box>
              <Typography>{projectName}</Typography>
            </Stack>
          );
      })}
      {canAddNew &&
        <Stack
          direction='row'
          alignItems='center'
          gap={4}
          px={3}
          mx={3}
          py={2}
          sx={{
            backgroundColor: '#f1f1f1',
            borderRadius: 2,
            cursor: 'pointer',
            transition: '0.2s',
            '&:hover': { backgroundColor: 'rgb(246,246,246)' }
          }}
          onClick={() => onProjectClick('')}
        >
          <Box sx={{
            backgroundColor: 'rgb(255,255,255)',
            borderRadius: 2,
            width: 26,
            height: 26,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Typography sx={{color: '#0066ff', fontSize: 20, fontWeight: 500}}>+</Typography>
          </Box>
          <Typography>New Project</Typography>
        </Stack>
      }
    </Stack>
  );
};

export default Projects;
